import React from "react";
import { Typography, Grid, Checkbox } from "@material-ui/core";
import { getAppColor } from "src/app/selectors";
import { useSelector } from "react-redux";

const CheckboxSection = ({ arr, keys, index, handleClick }) => {

  const color = useSelector(getAppColor);
  const names = ["Grupos", "Acumuladores", "Nóminas"];

  return (
    <Grid container item style={{ paddingLeft: 20, paddingRight: 20 }} md={4} sm={6} xs={12}>
      <Grid container style={{ cursor: "pointer " }}>
        <Grid item xs={12}>
          <Typography style={{ color: color, fontWeight: 800, fontSize: "1.1rem" }}>{names[index]}</Typography>
        </Grid>
      </Grid>
      <div>
        <Grid container style={{ paddingTop: 5, maxHeight: 504, overflowX: "hidden", overflowY: "auto" }}>
          {
            arr.map(item => {
              const { key, value } = item;
              const isMandatory = keys[key];

              return (
                <Grid
                  key={key}
                  container
                  alignItems="center"
                  onClick={() => handleClick(key, !isMandatory)}
                  style={{ cursor: "pointer" }}
                >
                  <Grid item xs={11}>
                    <Typography className="whiteText">{value}</Typography>
                  </Grid>
                  <Grid container item xs={1} justify="center">
                    <Checkbox checked={isMandatory} />
                  </Grid>
                </Grid>
              )
            })
          }
        </Grid>
      </div>
    </Grid>
  )
}

const areEqual = (prevProps, nextProps) => {
  return (prevProps.arr === nextProps.arr && prevProps.keys === nextProps.keys && prevProps.index === nextProps.index)
}

export default React.memo(CheckboxSection, areEqual);
