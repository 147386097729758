import React from "react";
import { useDispatch } from "react-redux";
import { TextField, Grid, Typography } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createConceptGroupsAction, updateConceptGroupsAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleForm from "./useHandleForm";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, length, handleClose } = props;
  const dispatch = useDispatch();

  const create = (dataToSend) => {
    dispatch(createConceptGroupsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    dispatch(updateConceptGroupsAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    formData,
    setFormValue,
    submit
  } = useHandleForm(data, create, modify, openValidationError);

  return (
    <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
      <Grid container item xs={12}>
        <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* numero */}
          <TextField
            disabled
            fullWidth
            margin={"none"}
            label={"Número del grupo"}
            value={data?.number || length + 1}
          />
        </Grid>
        <Grid container item alignItems="center" xs={12} sm={6} md={9} style={gridStyle}> {/* nombre */}
          <TextField
            disabled={data?.inUse}
            required
            fullWidth
            margin={"none"}
            label={"Nombre del grupo"}
            value={formData.conceptGroup}
            onChange={(e) => setFormValue(e.target.value, "conceptGroup")}
            inputProps={{ maxLength: 60 }}
          />
        </Grid>
        {
          data?.inUse &&
          <Typography className="whiteText" style={{ marginLeft: 10, fontSize: 14 }}>
            {'El nombre no puede modificarse porque está siendo utilizado en fórmulas de conceptos o funciones'}
          </Typography>
        }
      </Grid>
      {
        !Boolean(data?.inUse) &&
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      }
    </Grid>
  );
}

export default Form;
