import React, { useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getApplications,
  getMaxSequences,
  getLoading,
  getIsAdmin,
} from "../../../selectors";
import { createFunctionAction, modifyFunctionAction } from "../../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleConceptsFormulasForm from "./useHandleFunctionForm";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import { withStyles } from "@material-ui/core/styles";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import TabMenu from "@icarius-common/tabMenu";

const gridStyle = { height: 75, padding: "0px 10px" };

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {

  const {
    data,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();
  const isAdmin = useSelector(getIsAdmin);

  const loading = useSelector(getLoading);
  const applications = useSelector(getApplications);
  const maxSequences = useSelector(getMaxSequences);

  const [optionToShow, setOptionToShow] = useState(0);

  const create = (dataToSend) => {
    dispatch(createFunctionAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    dispatch(modifyFunctionAction(formData))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openCodeError = () => {
    dispatch(openSnackbarAction({
      msg: "El nombre de la función solo puede contener caracteres de la: “a” a la “z”. La “ñ” no está permitida y tampoco espacios dentro del nombre.",
      severity: "error",
      duration: 10000
    }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleConceptsFormulasForm(data, maxSequences, create, modify, openValidationError, openCodeError);

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  const getLabelForUsePersonalizedFormula = (formData) => {
    let label = "Estándar activa";
    if (formData) {
      const { formula, formulaPersonalizada } = formData;
      if (formula || formulaPersonalizada) {
        if (formula) {
          label = "Estándar activa";
        }

        if (formulaPersonalizada) {
          label = "Personalización activa";
        }
      } else {
        label = "Estándar activa";
      }
    }

    return label;
  }
  
  if (optionToShow === 0 && formData.formulaPersonalizada) {
    setOptionToShow(1);
  }

  const sequenceArr = useMemo(() => {
    if (!formData.application) return [];

    const amount = (formData.application === "P" || formData.application === "C") ? 1 : 99;

    return Array(amount).fill().map((_, i) => ({ key: i + 1, value: i + 1 }));
  }, [formData.application])

  return (
    <CommonPage
      columnDefPage={paths.conceptsAndFormulas}
      title={"Funciones de cálculo"}
      isNotGridPage
      menuItems={[goBackItem]}
      isLoading={loading}
    >
      <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* name */}
            <TextField
              disabled={!Boolean(isCreate)}
              required
              fullWidth
              margin={"none"}
              label={"Nombre de la función"}
              onChange={(e) => setFormValue(e.target.value, "name")}
              value={formData.name}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* descripcion */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Descripción de la función"}
              value={formData.description}
              onChange={(e) => setFormValue(e.target.value, "description")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* application */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-application-${data ? data.internalCode : "new"}`}>{"Aplicación"}</InputLabel>
              <Select
                value={formData.application}
                labelId={`label-application-${data ? data.internalCode : "new"}`}
                id={`select-application-${data ? data.internalCode : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "application")}
                margin={"none"}
              >
                {
                  applications && applications.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* secuencia */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-seq-${data ? data.internalCode : "new"}`}>{"Secuencia de cálculo"}</InputLabel>
              <Select
                value={formData.sequence}
                labelId={`label-seq-${data ? data.internalCode : "new"}`}
                id={`select-seq-${data ? data.internalCode : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "sequence")}
                margin={"none"}
              >
                {
                  sequenceArr.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={12} direction="row" justify="space-between" alignItems="center" style={{ marginTop: 15, marginLeft: 10 }}>
            <Grid container item xs={12} md={7}>
              <Grid container item xs={12} md={6}>
                <TabMenu
                  options={["Estandar", "Personalizada"]}
                  optionSelected={optionToShow}
                  onClick={setOptionToShow}
                />
              </Grid>
            </Grid>
            <Grid
              style={{ paddingTop: 5 }}
              container
              direction="row"
              justify="flex-end"
              item
              md={5}
              xs={12}
            >
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(formData.formulaPersonalizada)}
                    onChange={() => setFormValue(!formData.usePersonalizedFormula, "usePersonalizedFormula")}
                    value={formData.usePersonalizedFormula}
                    color="primary"
                    disabled
                  />
                }
                label={getLabelForUsePersonalizedFormula(formData)}
                labelPlacement="start"
                style={{ marginRight: 5 }}
              />
            </Grid>
          </Grid>
          {optionToShow === 0 && <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Formula */}
            <TextField
              fullWidth
              label="Fórmula"
              variant="outlined"
              multiline
              rows={25}
              margin={"none"}
              value={formData.formula}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
              onChange={(e) => isAdmin && setFormValue(e.target.value, "formula")}
            />
          </Grid>}
          {optionToShow === 1 && <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Formula personalizada */}
            <TextField
              fullWidth
              label="Fórmula personalizada"
              variant="outlined"
              multiline
              rows={25}
              margin={"none"}
              value={formData.formulaPersonalizada}
              onChange={(e) => setFormValue(e.target.value, "formulaPersonalizada")}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>}
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ marginBottom: 35 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
    </CommonPage>
  );
}

export default withStyles(styles)(Form);
