import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";
import { importStructuresAction } from "../../actions";
import FileList from "@icarius-common/fileUpload/fileList";
import FileInput from "@icarius-common/fileUpload/fileInput";

const UploadFileDialog = (props) => {

  const { open, isLoading, handleClose } = props;

  const [failedDocuments, setFailedDocuments] = useState(null);
  const [files, setFiles] = useState(null);
  
  const dispatch = useDispatch();

  const handleSubmit = async () => {
    // ver si manda failed documents
    setFailedDocuments(null);
    if (Boolean(files)) {
      let dataToSend = {
        file: files[0],
      }

      dispatch(importStructuresAction(dataToSend))
        .then((resp) => {
          if (resp?.status === 200) {
            handleClose();
          } else {
            if (resp?.data?.invalidFiles) {
              setFailedDocuments(resp.data.invalidFiles)
            }
          }
        });
    }
  };

  if (isLoading) return null;

  return (
    <Dialog
      open={open}
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      maxWidth={"sm"}
      fullWidth={true}
    >
      <div className={"dialog-container"}>
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          {"Subir archivo"}
          <DialogTitleDivider />
        </DialogTitle>
        <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
        <DialogContent>
        <FileInput
            acceptedFormats={['.xlsx', '.xls']}
            handleChange={(files) => setFiles(files)}
            preSubmitCallback={() => setFailedDocuments(null)}
          />
          <FileList
            files={files}
            filesWithError={failedDocuments}
            handleClick={() => setFiles(null)}
          />
        </DialogContent>
        <DialogActions>
          <ButtonDialogAction onClick={handleClose} text={getLocalizedString("cancel")} />
          <ButtonDialogAction onClick={handleSubmit} isAccept text={getLocalizedString("accept")} />
        </DialogActions>
      </div>
    </Dialog>
  );
}

export default UploadFileDialog;
