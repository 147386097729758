import NAME from "./constants";

//Conceptos
export const GET_CONCEPTS_AND_FORMULAS = NAME + "/GET_CONCEPTS_AND_FORMULAS";
export const GET_CONCEPTS_AND_FORMULAS_FULFILLED = NAME + "/GET_CONCEPTS_AND_FORMULAS_FULFILLED";
export const GET_CONCEPTS_AND_FORMULAS_REJECTED = NAME + "/GET_CONCEPTS_AND_FORMULAS_REJECTED";

export const SEARCH_CONCEPTS = NAME + "/SEARCH_CONCEPTS";
export const SEARCH_CONCEPTS_FULFILLED = NAME + "/SEARCH_CONCEPTS_FULFILLED";
export const SEARCH_CONCEPTS_REJECTED = NAME + "/SEARCH_CONCEPTS_REJECTED";

export const GET_CONCEPT_DETAILS = NAME + "/GET_CONCEPT_DETAILS";
export const GET_CONCEPT_DETAILS_FULFILLED = NAME + "/GET_CONCEPT_DETAILS_FULFILLED";
export const GET_CONCEPT_DETAILS_REJECTED = NAME + "/GET_CONCEPT_DETAILS_REJECTED";

export const CLEAN_CONCEPT_DETAILS  = NAME + "/CLEAN_CONCEPT_DETAILS";

export const CREATE_CONCEPTS_AND_FORMULAS = NAME + "/CREATE_CONCEPTS_AND_FORMULAS";
export const CREATE_CONCEPTS_AND_FORMULAS_FULFILLED = NAME + "/CREATE_CONCEPTS_AND_FORMULAS_FULFILLED";
export const CREATE_CONCEPTS_AND_FORMULAS_REJECTED = NAME + "/CREATE_CONCEPTS_AND_FORMULAS_REJECTED";

export const MODIFY_CONCEPTS_AND_FORMULAS = NAME + "/MODIFY_CONCEPTS_AND_FORMULAS";
export const MODIFY_CONCEPTS_AND_FORMULAS_FULFILLED = NAME + "/MODIFY_CONCEPTS_AND_FORMULAS_FULFILLED";
export const MODIFY_CONCEPTS_AND_FORMULAS_REJECTED = NAME + "/MODIFY_CONCEPTS_AND_FORMULAS_REJECTED";

export const DELETE_CONCEPTS_AND_FORMULAS = NAME + "/DELETE_CONCEPTS_AND_FORMULAS";
export const DELETE_CONCEPTS_AND_FORMULAS_FULFILLED = NAME + "/DELETE_CONCEPTS_AND_FORMULAS_FULFILLED";
export const DELETE_CONCEPTS_AND_FORMULAS_REJECTED = NAME + "/DELETE_CONCEPTS_AND_FORMULAS_REJECTED";

export const DUPLICATE_CONCEPTS_AND_FORMULAS = NAME + "/DUPLICATE_CONCEPTS_AND_FORMULAS";
export const DUPLICATE_CONCEPTS_AND_FORMULAS_FULFILLED = NAME + "/DUPLICATE_CONCEPTS_AND_FORMULAS_FULFILLED";
export const DUPLICATE_CONCEPTS_AND_FORMULAS_REJECTED = NAME + "/DUPLICATE_CONCEPTS_AND_FORMULAS_REJECTED";

export const FORM_FILE_UPLOAD = NAME + "/FORM_FILE_UPLOAD";
export const FORM_FILE_UPLOAD_REJECTED = NAME + "/FORM_FILE_UPLOAD_REJECTED";
export const FORM_FILE_UPLOAD_FULFILLED = NAME + "/FORM_FILE_UPLOAD_FULFILLED";

//Funciones
export const GET_FUNCTIONS = NAME + "/GET_FUNCTIONS";
export const GET_FUNCTIONS_FULFILLED = NAME + "/GET_FUNCTIONS_FULFILLED";
export const GET_FUNCTIONS_REJECTED = NAME + "/GET_FUNCTIONS_REJECTED";

export const SEARCH_FUNCTIONS = NAME + "/SEARCH_FUNCTIONS";
export const SEARCH_FUNCTIONS_FULFILLED = NAME + "/SEARCH_FUNCTIONS_FULFILLED";
export const SEARCH_FUNCTIONS_REJECTED = NAME + "/SEARCH_CONCEPTS_REJECTED";

export const CREATE_FUNCTION = NAME + "/CREATE_FUNCTION";
export const CREATE_FUNCTION_FULFILLED = NAME + "/CREATE_FUNCTION_FULFILLED";
export const CREATE_FUNCTION_REJECTED = NAME + "/CREATE_FUNCTION_REJECTED";

export const MODIFY_FUNCTION = NAME + "/MODIFY_FUNCTION";
export const MODIFY_FUNCTION_FULFILLED = NAME + "/MODIFY_FUNCTION_FULFILLED";
export const MODIFY_FUNCTION_REJECTED = NAME + "/MODIFY_FUNCTION_REJECTED";

export const DELETE_FUNCTION = NAME + "/DELETE_FUNCTION";
export const DELETE_FUNCTION_FULFILLED = NAME + "/DELETE_FUNCTION_FULFILLED";
export const DELETE_FUNCTION_REJECTED = NAME + "/DELETE_FUNCTION_REJECTED";

export const DUPLICATE_FUNCTION = NAME + "/DUPLICATE_FUNCTION";
export const DUPLICATE_FUNCTION_FULFILLED = NAME + "/DUPLICATE_FUNCTION_FULFILLED";
export const DUPLICATE_FUNCTION_REJECTED = NAME + "/DUPLICATE_FUNCTION_REJECTED";
