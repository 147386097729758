import React, { useState } from "react";
import { TextField, Grid, Typography, Button, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { createConceptsSetAction, updateConceptsSetAction } from "../../actions";
import useHandleForm from "./useHandleForm";
import { useDispatch, useSelector } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import UserSelectionDialog from "../userSelectionDialog/dialog";
import { getSetTypes } from "../../selectors";

const gridStyle = { height: 75, padding: "0px 10px" };

const Form = (props) => {

  const { data, concepts, handleClose, rowsToUse, getGroupsText, types } = props;

  const [groupDialogIsOpen, setGroupDialogIsOpen] = useState(false);

  const dispatch = useDispatch();

  const setTypes = useSelector(getSetTypes);

  const formatConcepts = (code, concepts, types, setType) => {
    const formattedConcepts = [];

    concepts.split(",").forEach((item, index) => {
      formattedConcepts.push({
        type: setType,
        code: code,
        conceptCode: item,
        typeValue: types[index],
        order: (index + 1)
      })
    })

    return formattedConcepts;
  }

  const create = (dataToSend) => {
    const set = { code: dataToSend.code, name: dataToSend.name, type: dataToSend.setType };
    const concepts = formatConcepts(dataToSend.code, dataToSend.concepts, dataToSend.types.split(","), dataToSend.setType);

    dispatch(createConceptsSetAction(set, concepts))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (dataToSend) => {
    const set = { code: dataToSend.code, name: dataToSend.name, type: dataToSend.setType };

    let oldConcepts = [];

    data.concepts.forEach((item, index) => {
      oldConcepts.push({
        type: dataToSend.setType,
        code: data.code,
        conceptCode: item,
        typeValue: data.types[index],
        order: (index + 1)
      })
    });

    const newConcepts = formatConcepts(dataToSend.code, dataToSend.concepts, dataToSend.types.split(","), dataToSend.setType);
    
    dispatch(updateConceptsSetAction(set, oldConcepts, newConcepts))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit,
    conceptsTypesSetFormValue
  } = useHandleForm(data, create, modify, openValidationError, rowsToUse);

  const handleSubmit = () => {
    if (!formData.concepts.length) {
      dispatch(openSnackbarAction({ msg: 'Debe seleccionar al menos un concepto', severity: "error" }));
      return;
    }

    submit();
  }

  return (
    <>
      <Grid container item xs={12} direction="row" style={{ paddingTop: 10 }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* codigo */}
            <TextField
              disabled={!Boolean(isCreate)}
              required
              fullWidth
              margin={"none"}
              label={"Código del conjunto"}
              onChange={(e) => setFormValue(e.target.value, "code")}
              value={formData.code}
              inputProps={{ maxLength: 12 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre del conjunto"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={4} style={gridStyle}> {/* tipo */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-type-${data ? data.code : "new"}`}>{'Tipo de conjunto'}</InputLabel>
              <Select
                value={formData.setType}
                labelId={`label-type-${data ? data.code : "new"}`}
                id={`select-type-${data ? data.code : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "setType")}
                margin={"none"}
              >
                {
                  setTypes && setTypes.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}>
            <div style={{ display: "flex", alignItems: 'center', paddingRight: 20 }}>
              <Button variant="outlined" onClick={() => setGroupDialogIsOpen(true)} style={{ minWidth: 200, marginRight: 20 }}>
                {'Seleccionar conceptos'}
              </Button>
              <Typography className="whiteText">
                {`${getGroupsText(formData)} seleccionados`}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ paddingBottom: 20 }}>
          <ButtonDialogAction isAccept onClick={handleSubmit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>
      {
        groupDialogIsOpen &&
        <UserSelectionDialog
          open={groupDialogIsOpen}
          concepts={concepts}
          types={types}
          title={'Ver conceptos'}
          firstColumnTitle={'Conceptos en el conjunto'}
          preselected={formData.concepts} //array de strings con key del primero seleccionado, si no hay, [] o [""]
          typesPreselected={formData.types}
          handleClose={() => setGroupDialogIsOpen(false)}
          handleSubmit={(concepts, types) => conceptsTypesSetFormValue(concepts, types)}
          filterPlaceholderText={'Buscar concepto por nombre'}
          showAmount
        />
      }
    </>
  );
}

export default Form;
