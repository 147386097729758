import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getGrupos,
  getNominas,
  getAcumuladores,
  getDisponibilidad,
  getFamilia,
  getClasificacion,
  getLoading,
  getMaxSeq,
  getCheckboxes,
  getFormula,
  getFormulaPersonalizada,
  getEsFormulaPersonalizada,
  getDiscountArray,
  getIsAdmin,
  getIsLoadingFileUpload,
} from "../../../selectors";
import { CLEAN_CONCEPT_DETAILS } from "../../../actionTypes";
import {
  createConceptAndFormulasAction,
  modifyConceptAndFormulasAction,
  uploadConceptsFormulasFormFileAction,
} from "../../../actions";
import {
  TextField,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
  Button,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import useHandleConceptsFormulasForm from "./useHandleConceptsFormulasForm";
import useHandleKeys from "./useHandleKeys";
import CommonPage from "@icarius-common/commonPage";
import paths from "@icarius-localization/paths";
import CheckboxSection from "./checkboxSection";
import { withStyles } from "@material-ui/core/styles";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openDialogAction } from "@icarius-common/dialog/actions";
import { GetAppIcon, DeleteIcon, VisibilityIcon } from "@icarius-icons/index";
import { downloadFromURL, previewFromURL } from "@icarius-utils/download";
import { RESOURCES_ENDPOINT } from "@icarius-connection/endpoints";
import TabMenu from "@icarius-common/tabMenu";

const gridStyle = { height: 75, padding: "0px 10px" };

const acceptedFormatsFile = ['jpg', 'bmp', 'png', 'jpeg', 'rar', 'zip', 'gz', 'tar', 'xls', 'xlsx', 'txt', 'rtf', 'doc', 'docx', 'pdf', 'html', 'ppt', 'pptx'];

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  cssOutlinedInput: {
    color: "var(--mainText) !important",
    '&$cssFocused $notchedOutline': {
      color: "var(--mainText) !important",
    }
  },

  cssFocused: {
    color: "var(--mainText) !important",
  },

  notchedOutline: {
    borderColor: 'var(--icons) !important',
    color: "var(--mainText) !important",
  },
});

const Form = (props) => {
  const {
    data,
    handleClose,
    classes,
  } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    return () => dispatch({ type: CLEAN_CONCEPT_DETAILS });
  }, [dispatch, data])

  const loading = useSelector(getLoading);
  const isLoadingFileUpload = useSelector(getIsLoadingFileUpload);
  const isAdmin = useSelector(getIsAdmin);
  const disponibilidadArr = useSelector(getDisponibilidad);
  const familiaArr = useSelector(getFamilia);
  const clasificacionArr = useSelector(getClasificacion);

  const grupos = useSelector(getGrupos);
  const nominas = useSelector(getNominas);
  const acumuladores = useSelector(getAcumuladores);
  const discountArray = useSelector(getDiscountArray);

  const checkboxes = useSelector(getCheckboxes);
  const maxSeq = useSelector(getMaxSeq);
  const formula = useSelector(getFormula);
  const formulaPersonalizada = useSelector(getFormulaPersonalizada);
  const esFormulaPersonalizada = useSelector(getEsFormulaPersonalizada);

  const [optionToShow, setOptionToShow] = useState(0);

  /*
    checkboxesNamesArr es un array de array que tiene los nombres de cada item
    checkboxesValuesArr es un array que tiene el boolean de cada item
    en useHandleKeys, se juntan para armar un array que tiene los key de cada item, y su value
    Si checkboxes no existe, se usa un array vacio, y se setea false (para el ALTA)
  */

  const checkboxesNamesArr = useMemo(() => [grupos, acumuladores, nominas], [grupos, acumuladores, nominas]);
  const checkboxesValuesArr = useMemo(() => {
    return checkboxes ? [...checkboxes.grupos, ...checkboxes.acumuladores, ...checkboxes.nominas] : [];
  }, [checkboxes]);
  const { keys, changeKey } = useHandleKeys(checkboxesNamesArr, checkboxesValuesArr);

  const formatCheckboxArrayToSend = () => {

    const constructArray = (initialArray) => {
      return initialArray.map(item => ({ key: item.key, value: keys?.hasOwnProperty(item.key) ? keys[item.key] : "" }));
    }

    return {
      "acumuladores": constructArray(acumuladores),
      "nominas": constructArray(nominas),
      "grupos": constructArray(grupos),
    }
  }

  const create = (formData) => {
    const dataToSend = { ...formData, ...formatCheckboxArrayToSend() };
    dispatch(createConceptAndFormulasAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const modify = (formData) => {
    const dataToSend = { ...formData, ...formatCheckboxArrayToSend() };
    dispatch(modifyConceptAndFormulasAction(dataToSend))
      .then((res) => res?.status === 200 && handleClose());
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openCodeValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'El código del concepto no es válido. Puede contener únicamente letras, números y los caracteres "-", "_"', severity: "error", duration: null }));
  }

  const openNoFormulaDialog = () => {
    dispatch(openDialogAction({
      title: 'Atención',
      msg: 'El concepto que quiere actualizar no tiene fórmulas, ni la fórmula estándar ni la personalizada, por ende, para poder considerarlo en un proceso de cálculo, este concepto deberá ingresarse siempre con "Valor informado”, para ello debe seleccionar la opción "Valor obligatorio" en el campo "Estructura de ingreso".',
      acceptOnly: true
    }));
  }

  const {
    isCreate,
    formData,
    setFormValue,
    submit
  } = useHandleConceptsFormulasForm(
    data,
    formula,
    formulaPersonalizada,
    esFormulaPersonalizada,
    maxSeq,
    familiaArr,
    clasificacionArr,
    disponibilidadArr,
    create,
    modify,
    openValidationError,
    openCodeValidationError,
    openNoFormulaDialog
  );


  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleClose}
      type={"goBack"}
    />

  const filteredClasificacionArr = clasificacionArr && clasificacionArr.filter((item) => {
    if (formData.family === "H") {
      return item.key === "1" || item.key === "3"
    }
    if (formData.family === "D") {
      return item.key === "2" || item.key === "4"
    }
    return item.key === "";
  });

  const handleFileImageUpload = e => {
    const dataToSend = {
      FILES: e.target.files[0],
    }

    dispatch(uploadConceptsFormulasFormFileAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          setFormValue(resp.data.file, "attachment");
        } else {
          dispatch(openSnackbarAction({ msg: "Tipo de archivo no admitido", severity: "error", duration: 10000 }));
        }
      })
  }

  const getLabelForUsePersonalizedFormula = (formData) => {
    let label = "Estándar activa";
    if (formData) {
      const { formula, formulaPersonalizada } = formData;
      if (formula || formulaPersonalizada) {
        if (formula) {
          label = "Estándar activa";
        }

        if (formulaPersonalizada) {
          label = "Personalización activa";
        }
      } else {
        label = "Estándar activa";
      }
    }

    return label;
  }

  if (optionToShow === 0 && formData.formulaPersonalizada) {
    setOptionToShow(1);
  }

  return (
    <CommonPage
      columnDefPage={paths.conceptsAndFormulas}
      title={getLocalizedString("conceptsAndFormulas")}
      isNotGridPage
      menuItems={[goBackItem]}
      isLoading={loading || isLoadingFileUpload}
    >
      <Grid container item xs={12} direction="row" style={{ width: "95%", margin: "auto" }}>
        <Grid container item xs={12}>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* codigo */}
            <TextField
              disabled={!Boolean(isCreate)}
              required
              fullWidth
              margin={"none"}
              label={"Codigo del concepto"}
              onChange={(e) => setFormValue(e.target.value, "code")}
              value={formData.code}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre del concepto"}
              value={formData.name}
              onChange={(e) => setFormValue(e.target.value, "name")}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* secuencia */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Secuencia de cálculo"}
              value={formData.sequence}
              onChange={(e) => setFormValue(e.target.value, "sequence")}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* ID */}
            <TextField
              fullWidth
              margin={"none"}
              label={"ID"}
              value={formData.id}
              onChange={(e) => setFormValue(e.target.value, "id")}
              inputProps={{ maxLength: 60 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* familia */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-family-${data ? data.internalCode : "new"}`}>{"Familia"}</InputLabel>
              <Select
                value={formData.family}
                labelId={`label-family-${data ? data.internalCode : "new"}`}
                id={`select-family-${data ? data.internalCode : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "family")}
                margin={"none"}
              >
                {
                  familiaArr && familiaArr.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* clasificacion */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required={Boolean(formData.family) && formData.family !== "O"} id={`label-classification-${data ? data.internalCode : "new"}`}>
                {"Clasificación"}
              </InputLabel>
              <Select
                value={formData.classification}
                labelId={`label-classification-${data ? data.internalCode : "new"}`}
                id={`select-classification-${data ? data.internalCode : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "classification")}
                margin={"none"}
              >
                {
                  filteredClasificacionArr && filteredClasificacionArr.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* disponibilidad */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-availability-${data ? data.internalCode : "new"}`}>{"Disponibilidad"}</InputLabel>
              <Select
                value={formData.availability}
                labelId={`label-availability-${data ? data.internalCode : "new"}`}
                id={`select-availability-${data ? data.internalCode : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "availability")}
                margin={"none"}
              >
                {
                  disponibilidadArr && disponibilidadArr.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container item alignItems="center" xs={12} sm={6} md={3} style={gridStyle}> {/* Descuento de anticipo */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel shrink id={`label-discount-${data ? data.internalCode : "new"}`}>{"Descuento de anticipo"}</InputLabel>
              <Select
                value={formData.discount}
                labelId={`label-discount-${data ? data.internalCode : "new"}`}
                id={`select-discount-${data ? data.internalCode : "new"}`}
                onChange={(e) => setFormValue(e.target.value, "discount")}
                margin={"none"}
                displayEmpty
              >
                <MenuItem className={"whiteText"} value={''}>
                  {'Sin valor'}
                </MenuItem>
                {
                  discountArray && discountArray.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
          <Grid container direction="row" alignItems="flex-start" style={{ marginTop: 20 }}> {/* checkboxes */}
            {
              Boolean(keys) && checkboxesNamesArr.map((arr, index) => (
                <CheckboxSection
                  index={index}
                  key={index}
                  arr={arr}
                  keys={keys}
                  handleClick={changeKey}
                />
              ))
            }
          </Grid>
          <Grid container item xs={12} direction="row" justify="space-between" alignItems="center" style={{ marginTop: 15, marginLeft: 10 }}>
            <Grid container item xs={12} md={7}>
              <Grid container item xs={12} md={6}>
                <TabMenu
                  options={["Estandar", "Personalizada"]}
                  optionSelected={optionToShow}
                  onClick={setOptionToShow}
                />
              </Grid>
            </Grid>
            <Grid
              style={{ paddingTop: 5 }}
              container
              direction="row"
              justify="flex-end"
              item
              md={5}
              xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={Boolean(formData.formulaPersonalizada)}
                    onChange={() => setFormValue(!formData.usePersonalizedFormula, "usePersonalizedFormula")}
                    value={formData.usePersonalizedFormula}
                    color="primary"
                    disabled
                  />
                }
                label={getLabelForUsePersonalizedFormula(formData)}
                labelPlacement="start"
                style={{ marginRight: 5 }}
              />
            </Grid>
          </Grid>
          {
            optionToShow === 0 &&
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Formula */}
              <TextField
                fullWidth
                label="Fórmula"
                variant="outlined"
                multiline
                rows={25}
                margin={"none"}
                value={formData.formula}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
                onChange={(e) => isAdmin && setFormValue(e.target.value, "formula")}
              />
            </Grid>
          }
          {
            optionToShow === 1 &&
            <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Formula personalizada */}
              <TextField
                fullWidth
                label="Fórmula personalizada"
                variant="outlined"
                multiline
                rows={25}
                margin={"none"}
                value={formData.formulaPersonalizada}
                onChange={(e) => setFormValue(e.target.value, "formulaPersonalizada")}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,
                    focused: classes.cssFocused,
                    notchedOutline: classes.notchedOutline,
                  }
                }}
                inputProps={{ spellCheck: 'false' }}
              />
            </Grid>
          }
          <Grid container item alignItems="center" xs={12} style={{ padding: "20px 10px" }}> {/* Ayuda para el ingreso */}
            <TextField
              fullWidth
              label="Ayuda para el ingreso"
              variant="outlined"
              multiline
              rows={8}
              margin={"none"}
              value={formData.help}
              onChange={(e) => setFormValue(e.target.value, "help")}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                classes: {
                  root: classes.cssOutlinedInput,
                  focused: classes.cssFocused,
                  notchedOutline: classes.notchedOutline,
                }
              }}
              inputProps={{ spellCheck: 'false' }}
            />
          </Grid>
          <Grid item >
            <input
              accept={acceptedFormatsFile.map(item => `.${item}`).join(',')}
              type="file"
              id="fileInputUpload2"
              style={{ display: "none" }}
              onChange={(e) => handleFileImageUpload(e)}
            />
            <div style={{ height: 48, marginLeft: 10, display: 'flex', alignItems: 'center' }}>
              <label htmlFor="fileInputUpload2">
                <Button
                  variant={"contained"}
                  disableRipple={true}
                  className="whiteText"
                  component={"span"}
                >
                  {"Adjunto"}
                </Button>
              </label>
              {
                Boolean(formData["attachment"]) &&
                <>
                  <Tooltip title={'Ver'}>
                    <IconButton onClick={() => previewFromURL(RESOURCES_ENDPOINT + formData["attachment"])}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Descargar'}>
                    <IconButton onClick={() => downloadFromURL(RESOURCES_ENDPOINT + formData["attachment"], formData["attachment"].substr(formData["attachment"].lastIndexOf("/") + 1))}>
                      <GetAppIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={'Eliminar'}>
                    <IconButton onClick={() =>
                      dispatch(openDialogAction({
                        title: 'Atención',
                        msg: `¿Desea borrar el adjunto?`,
                        onConfirm: () => setFormValue(null, "attachment"),
                      }))}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            </div>
          </Grid>
        </Grid>
        <Grid container item xs={12} justify={"flex-end"} alignItems="center" style={{ marginBottom: 35 }}>
          <ButtonDialogAction isAccept onClick={submit} text={getLocalizedString("save")} />
        </Grid>
      </Grid>

    </CommonPage>
  );
}

export default withStyles(styles)(Form);
