import NAME from "./constants";

export const getLoading = store => store[NAME].loading;

export const getGridData = store => store[NAME].gridData;
export const getGrupos = store => store[NAME].grupos;
export const getNominas = store => store[NAME].nominas;
export const getAcumuladores = store => store[NAME].acumuladores;
export const getDisponibilidad = store => store[NAME].disponibilidad;
export const getFamilia = store => store[NAME].familia;
export const getClasificacion = store => store[NAME].clasificacion;
export const getCheckboxes = store => store[NAME].checkboxes;
export const getMaxSeq = store => store[NAME].maxSeq;
export const getFormula = store => store[NAME].formula;
export const getFormulaPersonalizada = store => store[NAME].formulaPersonalizada;
export const getEsFormulaPersonalizada = store => store[NAME].esFormulaPersonalizada;
export const getDiscountArray = store => store[NAME].discountArray;

export const getFunctionsGridData = store => store[NAME].functionsGridData;
export const getApplications = store => store[NAME].applications;
export const getMaxSequences = store => store[NAME].maxSequences;
export const getIsAdmin = store => store[NAME].isAdmin;
export const getIsLoadingFileUpload = store => store[NAME].isLoadingFileUpload;