import NAME from "./constants";

export const GET = NAME + "/GET_CONCEPT_GROUPS";
export const GET_FULFILLED = NAME + "/GET_CONCEPT_GROUPS_FULFILLED";
export const GET_REJECTED = NAME + "/GET_CONCEPT_GROUPS_REJECTED";

export const GET_DETAILS = NAME + "/GET_CONCEPT_GROUPS_DETAILS";
export const GET_DETAILS_FULFILLED = NAME + "/GET_CONCEPT_GROUPS_DETAILS_FULFILLED";
export const GET_DETAILS_REJECTED = NAME + "/GET_CONCEPT_GROUPS_DETAILS_REJECTED";

export const GET_CONCEPTS = NAME + "/GET_CONCEPTS";
export const GET_CONCEPTS_FULFILLED = NAME + "/GET_CONCEPTS_FULFILLED";
export const GET_CONCEPTS_REJECTED = NAME + "/GET_CONCEPTS_REJECTED";

export const CREATE = NAME + "/CREATE";
export const CREATE_FULFILLED = NAME + "/CREATE_FULFILLED";
export const CREATE_REJECTED = NAME + "/CREATE_REJECTED";

export const UPDATE = NAME + "/UPDATE_CONCEPT_GROUPS";
export const UPDATE_FULFILLED = NAME + "/UPDATE_CONCEPT_GROUPS_FULFILLED";
export const UPDATE_REJECTED = NAME + "/UPDATE_CONCEPT_GROUPS_REJECTED";

export const DELETE = NAME + "/DELETE";
export const DELETE_FULFILLED = NAME + "/DELETE_FULFILLED";
export const DELETE_REJECTED = NAME + "/DELETE_REJECTED";

export const DUPLICATE = NAME + "/DUPLICATE";
export const DUPLICATE_FULFILLED = NAME + "/DUPLICATE_FULFILLED";
export const DUPLICATE_REJECTED = NAME + "/DUPLICATE_REJECTED";

export const RESET_STATE = NAME + "/RESET_STATE";
