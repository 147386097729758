import { useState } from "react";
import { useDispatch } from "react-redux";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import * as yup from 'yup';

const useHandleForm = (data, createCallback, modifyCallback, invalidDataCallback, rowsToUse) => {

  const isCreate = !Boolean(data);
  const dispatch = useDispatch();

  const createInitialData = () => {

    const getInitialFieldData = (fieldName) => {
      if (fieldName === "concepts" || fieldName === "types") {
        return isCreate ? [] : data[fieldName];
      }

      if (fieldName === "setType") {
        return isCreate ? '' : data.type;
      }
      
      return isCreate ? "" : data[fieldName];
    }

    const fieldNames = ["code", "name", "concepts", "types", "setType"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData(fieldName)
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const regex = /^[(A-Z)]([A-Za-z]|\d|_)+$/;

    const schema = yup.object().shape({
      code: yup.string().max(12).matches(regex).required(),
      name: yup.string().max(100).required(),
      setType: yup.string().required(),
      concepts: yup.array().min(1).required(),
      types: yup.array().min(1).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        code: formData.code,
        name: formData.name,
        setType: formData.setType,
        concepts: formData.concepts.join(","),
        types: formData.types.join(","),
      };

      if (!isCreate) {
        dataToSend.code = formData.code;
      }

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }

  const checkIfNameCanBeUsed = (value, key) => {
    const arrToCompare = rowsToUse.filter(row => row[key] === value);
    return arrToCompare.length === 0;
  }

  const conceptsTypesSetFormValue = (concepts, types) => {
    setFormData({
      ...formData,
      concepts, types
    })
  }

  const setFormValue = (value, fieldName) => {
    if (fieldName === "code") {
      if (Boolean(value) && !checkIfNameCanBeUsed(value, "code")) {
        dispatch(openSnackbarAction({ msg: 'El código de conjunto ya se encuenta en uso', severity: "error" }));
      }
    }

    if (fieldName === "name") {
      if (Boolean(value) && !checkIfNameCanBeUsed(value, "name")) {
        dispatch(openSnackbarAction({ msg: 'El nombre de conjunto ya se encuenta en uso', severity: "error" }));
      }
    }

    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { isCreate, formData, setFormValue, submit, conceptsTypesSetFormValue };
}

export default useHandleForm;
