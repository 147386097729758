import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { duplicateFunctionAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getApplications } from "@icarius-pages/conceptsFormulasFunctions/selectors"; 
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const DuplicateDialog = (props) => {

  const { open, data, handleClose } = props;

  const [ name, setName ] = useState("")
  const [ description, setDescription ] = useState(data ? data.description : "")
  const [ application, setApplication ] = useState(data ? data.typeId : "")

  const dispatch = useDispatch();
  const applications = useSelector(getApplications);

  const dataIsValid = () => {
    const codeRegex = /^[a-zA-Z]+$/;

    if(name && !codeRegex.test(name)){
      openCodeError();
      return false;
    }

    if((!name || name === "") || (!description || description === "") || (!application || application === "")){
      openValidationError();
      return false;
    }
    
    return true;
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openCodeError = () => {
    dispatch(openSnackbarAction({
      msg: "El nombre de la función solo puede contener caracteres de la: “a” a la “z”. La “ñ” no está permitida y tampoco espacios dentro del nombre.", 
      severity: "error",
      duration: 10000
    }));
  }

  const handleSave = () => {
    if(!dataIsValid()){
      return;
    }    

    const dataToSend = {      
      name, type: application, description, originalName: data.name,
    }
    
    dispatch(duplicateFunctionAction(dataToSend))
    .then((resp) => {
      if(resp.status === 200){
        handleClose();
      }
    })
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">  
        {"Duplicar función"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item xs={12} direction="column">
          <Typography className="whiteText" style={{ fontSize: "18px", fontWeight: 400 }}>
            {"Por favor, ingrese el nombre, la descripción y la aplicación de la función. Estos datos reemplazarán los originales."}
          </Typography>          
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}            
              label={"Nombre de la función"}
              onChange={(e) => setName(e.target.value?.length > 0 ? e.target.value.replace(/\s/g, "") : "")}
              value={name}
              inputProps={{ maxLength: 20 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* descr */}
            <TextField            
              required
              fullWidth
              margin={"none"}            
              label={"Descripción de la función"}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              inputProps={{ maxLength: 100 }}
            />
          </Grid>    
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* application */}
            <FormControl style={{ width: "100%" }}>
              <InputLabel required id={`label-application-${data ? data.internalCode : "new"}`}>{"Aplicación"}</InputLabel>
              <Select        
                value={application}
                labelId={`label-application-${data ? data.internalCode : "new"}`}
                id={`select-application-${data ? data.internalCode : "new"}`}
                onChange={(e) => setApplication(e.target.value)}
                margin={"none"}
              >
                {
                  applications && applications.map(item => (
                    <MenuItem
                      className={"whiteText"}
                      key={item.key}
                      value={item.key}>
                      {item.value}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>        
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleSave} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
