import paths from "@icarius-localization/paths";
import {
	numberComparatorMax2,
	numberFormatterMin0Max0,
	numberFilterParams,
} from "@icarius-table/number";

export default [
    {
        pages: [paths.calculationsFunctions],
        config: {
            headerName: "Nombre de la función",
            field: "name",
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.calculationsFunctions],
        config: {
            headerName: "Descripción de la función",
            field: "description",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.calculationsFunctions],
        config: {
            headerName: "Aplicación",
            field: "type",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.calculationsFunctions],
        config: {
            headerName: "Secuencia de cálculo",
            field: "sequence",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            chartDataType: "series",
            cellClass: "currencyValue",
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.calculationsFunctions],
        config: {
            headerName: "Fórmula",
            field: "Fórmula",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Código de concepto",
            field: "Código de concepto",
            filter: "agSetColumnFilter",
            checkboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelection: function (params) {
                return params.columnApi.getRowGroupColumns().length === 0;
            },
            headerCheckboxSelectionFilteredOnly: true,
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Nombre del concepto",
            field: "Nombre del concepto",
            filter: "agTextColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "ID",
            field: "ID",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Secuencia de cálculo",
            field: "Secuencia de cálculo",
            filter: "agTextColumnFilter",
            comparator: numberComparatorMax2,
            filterParams: numberFilterParams,
            cellClass: "currencyValueNoDecimal",
            valueFormatter: numberFormatterMin0Max0,
            enableValue: true,
            allowedAggFuncs: ["sum", "min", "max", "avg"],
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Familia",
            field: "Familia",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Fórmula",
            field: "Fórmula",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Clasificación",
            field: "Clasificación",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 01",
            field: "Grupo 01",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 02",
            field: "Grupo 02",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 03",
            field: "Grupo 03",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 04",
            field: "Grupo 04",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 05",
            field: "Grupo 05",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 06",
            field: "Grupo 06",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 07",
            field: "Grupo 07",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 08",
            field: "Grupo 08",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 09",
            field: "Grupo 09",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 10",
            field: "Grupo 10",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 11",
            field: "Grupo 11",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Grupo 12",
            field: "Grupo 12",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Acumuladores",
            field: "Acumuladores",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Nóminas",
            field: "Nóminas",
            filter: "agSetColumnFilter",
        }
    },
    {
        pages: [paths.conceptsAndFormulas],
        config: {
            headerName: "Disponibilidad",
            field: "Disponibilidad",
            filter: "agSetColumnFilter",
        }
    },
]