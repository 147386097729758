import React, { useState } from "react";
import { IconButton, Grid, Card, Tooltip } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import { DeleteIcon, FileCopyIcon } from "@icarius-icons";
import Form from "./form";
import DataContainer from "@icarius-common/abmComponents/dataContainer";
import { deleteConceptsSetAction } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import { getAppColor } from "src/app/selectors";
import { openDialogAction } from "@icarius-common/dialog/actions";

const DataItem = (props) => {

  const {
    data,
    concepts,
    closeNew,
    rowsToUse,
    types,
    handleOpenDuplicateDialog,
  } = props;

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const isCreate = !Boolean(data);

  const [isOpen, setIsOpen] = useState(isCreate); //si viene sin data, que este abierto por defecto

  const handleDeletePublication = () => {
    if (isCreate) {
      closeNew();
    } else {
      const set = { code: data.code, name: data.name };
      const concepts = [];
      data.concepts.forEach((item, index) => concepts.push({ type: 'ING', code: data.code, conceptCode: item, typeValue: data.types[index], order: (index + 1) }))

      dispatch(deleteConceptsSetAction(set, concepts));
    }
  }

  const handleClosePublication = () => {
    !isCreate && setIsOpen(prev => !prev);
  }

  const getGroupsText = (value) => {
    return `${value?.concepts?.length} concepto/s en el conjunto`
  }

  const handleOpenDeleteDialog = () => {
    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Esta seguro que desea eliminar al conjunto de conceptos?',
      onConfirm: handleDeletePublication,
    }));
  }

  return (
    <>
      <Card style={{ width: "100%", borderRadius: 10, background: (isOpen || isCreate) ? color : "", color: (isOpen || isCreate) && 'white' }}>
        <Grid container item xs={12} direction="row" style={{ padding: "10px 20px" }}>
          <Grid container item xs={11} style={{ cursor: !isCreate ? "pointer" : "" }} onClick={handleClosePublication}>
            {
              isCreate ?
                <DataContainer lg={12} sm={12} md={12} text={`Creación de conjunto de conceptos`} />
                :
                <>
                  <DataContainer sm={12} md={6} lg={2} text={data.code} />
                  <DataContainer sm={12} md={6} lg={5} text={data.name} />
                  {data.concepts && <DataContainer sm={12} md={6} lg={5} text={getGroupsText(data)} />}
                </>
            }
          </Grid>
          <Grid container direction="row" item xs={1} justify="space-between">
            {
              !isCreate &&
              <Tooltip title={getLocalizedString("duplicate")}>
                <IconButton size={"small"} disableRipple onClick={() => handleOpenDuplicateDialog(data)}>
                  <FileCopyIcon style={{ fill: "var(--icons)" }} fontSize="small" />
                </IconButton>
              </Tooltip>
            }
            <Tooltip title={getLocalizedString("delete")}>
              <IconButton size={"small"} disableRipple onClick={handleOpenDeleteDialog}>
                <DeleteIcon style={{ fill: "var(--icons)" }} fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Card>
      {
        isOpen &&
        <Form
          data={data}
          concepts={concepts}
          types={types}
          rowsToUse={rowsToUse}
          getGroupsText={getGroupsText}
          handleClose={isCreate ? closeNew : handleClosePublication}
        />
      }
    </>
  );
}

export default DataItem;
