import { useState } from "react";

const useHandleConceptsFormulasForm = (data, maxSequences, createCallback, modifyCallback, invalidDataCallback, openCodeError) => {
  const isCreate = !Boolean(data);

  const getDefaultSequence = () => {
    if (isCreate) return "";

    if (data.sequence) return data.sequence;

    if (data.typeId === "P" || data.typeId === "C") return 1;
    return maxSequences[data.typeId] + 1;
  }

  const [name, setName] = useState(isCreate ? "" : data.name);
  const [description, setDescription] = useState(isCreate ? "" : data.description);
  const [application, setApplication] = useState(isCreate ? "" : data.typeId);
  const [sequence, setSequence] = useState(getDefaultSequence());
  const [formula, setFormula] = useState(isCreate ? "" : data.formula);
  const [formulaPersonalizada, setFormulaPersonalizada] = useState(isCreate ? "" : data.formulaPersonalizada);
  const [usePersonalizedFormula, setUsePersonalizedFormula] = useState(isCreate ? true : data.esFuncPersonalizada === "Y");

  const formData = {
    name,
    description,
    application,
    sequence,
    formula,
    formulaPersonalizada,
    usePersonalizedFormula
  };

  const dataIsValid = () => {
    const codeRegex = /^[a-zA-Z]+$/;

    if (name && !codeRegex.test(name)) {
      openCodeError();
      return false;
    }

    if ((!name || name === "") || (!description || description === "") ||
      (!application || application === "") || ((!formula || formula === "") && (!formulaPersonalizada || formulaPersonalizada === ""))) {
      invalidDataCallback();
      return false;
    }

    return true;
  }

  const submit = () => {
    if (dataIsValid()) {
      let dataToSend = {
        name,
        type: application,
        description,
        formula,
        formulaPersonalizada,
        sequence,
        esFuncPersonalizada: usePersonalizedFormula ? "Y" : "N",
      };

      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    }
    return false;
  }

  const setFormValue = (value, fieldName) => {
    switch (fieldName) {
      case "name":
        setName(value?.length > 0 ? value.replace(/\s/g, "") : "");
        break;
      case "description":
        setDescription(value);
        break;
      case "application":
        setApplication(value);
        if (value === "P" || value === "C") { //si es P o C, setear 1
          setSequence(1);
          break;
        }
        if (sequence === "") { //si no es P o C, y no tiene seteada sequencia, setear el maximo + 1 de esa application
          setSequence(maxSequences[value] + 1);
          break;
        }
        break;
      case "formula":
        setFormula(value);
        break;
      case "sequence":
        setSequence(value);
        break;
      case "formulaPersonalizada":
        setFormulaPersonalizada(value);
        break;
      case "usePersonalizedFormula":
        setUsePersonalizedFormula(value);
        break;
      default: break;
    }
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleConceptsFormulasForm;
