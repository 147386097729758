import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTheme } from "@icarius-pages/login/selectors";
import { Grid, Typography, TextField, InputAdornment } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import UserSection from "./userSection";
import { SearchIcon } from "@icarius-icons/";

const useSetValueWithDelay = (input) => {

  //hook que pone delay en el seteo de un value, para inputs donde no quiero que actualice el state letra por letra 
  //ej, para inputs de busqueda, donde NO quiero que busque cada vez que escribo una letra, sino cuando termino

  const [value, setValue] = useState("");

  useEffect(() => {
    const timeOutId = setTimeout(() => setValue(input), 500);
    return () => clearTimeout(timeOutId);
  }, [input]);

  return value;
}

const UserSelectionColumn = (props) => {

  const [userInput, setUserInput] = useState(""); //para el input en si, e ir actualizando letra a letra

  //el texto del input cuando el user deja de escribir, es lo que se le envia a UserSection para filtrar
  const searchValue = useSetValueWithDelay(userInput);

  const { showAmount, filterPlaceholderText, firstColumnTitle, color, concepts, isActualMembersColumn, handleUserClick, setSelectedOrderedElements, types,
    setSelectTypeElements, typesOptions } = props;

  const theme = useSelector(getTheme);
  const filterBarColor = theme.theme === "dark" ? "#747474" : "#949494";

  return (
    <Grid container direction="row" item xs={12} md={5} style={{ marginBottom: 30 }}>
      <Grid item xs={12} style={{ paddingBottom: 20 }}>
        {
          isActualMembersColumn ?
            <div style={{ display: "flex", alignItems: "center", height: 32, width: 360 }}>
              <Typography variant="h6" className="whiteText" style={{ fontWeight: 600, fontSize: 18 }}>
                {firstColumnTitle}
              </Typography>
              {
                showAmount &&
                <Typography variant="h6" className="whiteText" style={{ paddingLeft: 40, fontWeight: 300, fontSize: 18 }}>
                  {concepts.length}
                </Typography>
              }
            </div>
            :
            <div style={{ display: "flex", alignItems: "center", height: 32, width: 260 }}>
              <TextField
                fullWidth
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={e => setUserInput(e.target.value)}
                style={{ borderBottom: `1px solid ${filterBarColor}` }}
                placeholder={filterPlaceholderText || getLocalizedString("groupSearchLabel")}
                type="text"
                value={userInput} />
            </div>
        }
      </Grid>
      <Grid item xs={12}>
        <UserSection
          searchValue={searchValue}
          color={color}
          concepts={concepts}
          types={types}
          isActualMembersColumn={isActualMembersColumn}
          handleUserClick={handleUserClick}
          setSelectedOrderedElements={setSelectedOrderedElements}
          setSelectTypeElements={setSelectTypeElements}
          typesOptions={typesOptions}
        />
      </Grid>
    </Grid>
  );
}

export default UserSelectionColumn;
