import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  Grid,
  DialogContent,
  DialogActions,
  DialogTitle,
  Dialog,
  TextField,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import PaperDraggable from "@icarius-common/paperDraggable";
import { duplicateConceptAndFormulasAction } from "../../actions";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import DialogTransition from "@icarius-common/dialogTransition";

const gridStyle = { height: 75, padding: "10px 0px" };

const DuplicateDialog = (props) => {

  const { open, data, handleClose } = props;

  const [code, setCode] = useState("");
  const [name, setName] = useState(data ? data["Nombre del concepto"] : "")

  const dispatch = useDispatch();

  const codeIsValid = () => {
    const codeRegex = /^([A-Z]|\d|_|-){0,8}$/;

    if (!code || code === "" || !codeRegex.test(code)) return false;
    return true;
  }

  const dataIsValid = () => {
    return !((!name || name === ""));
  }

  const openValidationError = () => {
    dispatch(openSnackbarAction({ msg: getLocalizedString("invalidData"), severity: "error", duration: 10000 }));
  }

  const openCodeValidationError = () => {
    dispatch(openSnackbarAction({ msg: 'El código del concepto no es válido. Puede contener únicamente letras, números y los caracteres "-", "_"', severity: "error", duration: null }));
  }

  const handleSave = () => {
    if (!codeIsValid()) {
      openCodeValidationError();
      return;
    }

    if (!dataIsValid()) {
      openValidationError();
      return;
    }

    const dataToSend = {
      "Código de concepto original": data["Código de concepto"],
      "Código de concepto": code,
      "Nombre del concepto": name,
    }

    dispatch(duplicateConceptAndFormulasAction(dataToSend))
      .then((resp) => {
        if (resp.status === 200) {
          handleClose();
        }
      })
  }

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {"Duplicar concepto"}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item xs={12} direction="column">
          <Typography className="whiteText" style={{ fontSize: "18px", fontWeight: 400 }}>
            {"Por favor, ingrese el código de concepto y nombre,. Estos datos reemplazarán los originales."}
          </Typography>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* codigo */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Codigo del concepto"}
              onChange={(e) => setCode(e.target.value ? e.target.value.toUpperCase() : "")}
              value={code}
              inputProps={{ maxLength: 8 }}
            />
          </Grid>
          <Grid container item alignItems="center" xs={12} style={gridStyle}> {/* nombre */}
            <TextField
              required
              fullWidth
              margin={"none"}
              label={"Nombre del concepto"}
              value={name}
              onChange={(e) => setName(e.target.value)}
              inputProps={{ maxLength: 40 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleSave} isAccept text={getLocalizedString("agree")} />
      </DialogActions>
    </Dialog>
  );
}

export default DuplicateDialog;
