import React, { useEffect, useState } from "react";
import { Grid, Typography, Checkbox, IconButton, useMediaQuery, ListItem, Select, MenuItem } from "@material-ui/core";
import { CloseIcon } from "@icarius-icons";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const useFilterEmployeesByName = (types, typesOptions, concepts, name, setSelectedOrderedElements, setSelectTypeElements) => {

  //hook que recibe array de empleados y los filtra comparando el name con el input
  const [filteredConcepts, setFilteredConcepts] = useState([]);
  const [typeValuesConcepts, setTypeValuesConcepts] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }) => {
    // dropped outside the list
    if (!destination) return;

    const newItems = reorder(filteredConcepts, source.index, destination.index);
    setFilteredConcepts(newItems);
    if (setSelectedOrderedElements) setSelectedOrderedElements(newItems);

    const newTypeValues = reorder(typeValuesConcepts, source.index, destination.index);
    setTypeValuesConcepts(newTypeValues);
    if (setSelectTypeElements) setSelectTypeElements(newTypeValues);
  };

  const onChangeTypeValue = (value, index) => {
    let newTypeValues = [...typeValuesConcepts];
    const elem = typesOptions.find(type => type.key === value);
    if (elem) {
      newTypeValues[index] = elem;

      setTypeValuesConcepts(newTypeValues);
      if (setSelectTypeElements) setSelectTypeElements(newTypeValues);
    }
  }

  useEffect(() => {
    let filteredArr = [...concepts];

    if (filteredArr && filteredArr.length > 0 && name && name !== "") {
      filteredArr = filteredArr.filter((employee) => employee.nombre && employee.nombre.toLowerCase().includes(name.toLowerCase()))
    }

    setFilteredConcepts(filteredArr);
    if (setSelectedOrderedElements) setSelectedOrderedElements(filteredArr);
  }, [concepts, name, setSelectedOrderedElements])

  useEffect(() => {
    setTypeValuesConcepts(types);
    if (setSelectTypeElements) setSelectTypeElements(types);
  }, [types, setSelectTypeElements])

  return {
    filteredConcepts,
    typeValuesConcepts,
    onDragEnd,
    onChangeTypeValue
  };
}

const UserSection = (props) => {

  const { searchValue, concepts, isActualMembersColumn, handleUserClick, setSelectedOrderedElements, types, setSelectTypeElements, typesOptions } = props;
  const { filteredConcepts, typeValuesConcepts, onDragEnd, onChangeTypeValue } = useFilterEmployeesByName(types, typesOptions, concepts, searchValue, setSelectedOrderedElements, setSelectTypeElements);

  const matchesLessThan600 = useMediaQuery(`(max-width:600px)`);

  if (isActualMembersColumn) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable style={{ transform: "none" }} droppableId="droppable-list">
          {provided => (
            <div ref={provided.innerRef} {...provided.droppableProps} style={{ overflowX: "hidden", height: matchesLessThan600 ? 150 : 300 }}>
              {
                filteredConcepts.map((item, index) => {
                  return (
                    <Draggable key={index} draggableId={index.toString()} index={index}>
                      {(provided) => {
                        const style = {
                          ...provided.draggableProps.style,
                          position: "relative",
                          left: "0px",
                          top: "0px",
                          fontSize: 18,
                        };

                        return <ListItem
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={style}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs={6} style={{ cursor: "pointer" }} onClick={() => handleUserClick(item.code)}>
                              <Typography variant="h6" className="whiteText" style={{ fontWeight: 100, fontSize: matchesLessThan600 ? 14 : 16 }}>
                                {item.nombre}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              <Select
                                value={typeValuesConcepts[index].key}
                                onChange={(e) => onChangeTypeValue(e.target.value, index)}
                              >
                                {
                                  typesOptions.map((subItem, index) =>
                                    <MenuItem
                                      className={"whiteText"}
                                      key={`${index}-${subItem.key}`}
                                      value={subItem.key}>
                                      {subItem.value}
                                    </MenuItem>
                                  )
                                }
                              </Select>
                            </Grid>
                            <Grid container alignItems="center" justify="center" item xs={1}>
                              {
                                isActualMembersColumn ?
                                  <IconButton onClick={() => handleUserClick(item.code)} color="inherit">
                                    <CloseIcon style={{ fill: "var(--icons)" }} />
                                  </IconButton>
                                  :
                                  <Checkbox onChange={() => handleUserClick(item.code)} checked={item.isChecked} />
                              }
                            </Grid>
                          </Grid>
                        </ListItem>
                      }
                      }
                    </Draggable>
                  )
                })
              }
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    )
  }

  return (
    <div style={{ overflowX: "hidden", height: matchesLessThan600 ? 150 : 300 }}>
      {
        filteredConcepts.map((item, index) => {
          return (
            <div
              key={index}
              style={{ height: 60, paddingBottom: 10 }}
            >
              <Grid
                container
                alignItems="center"
              >
                <Grid item xs={8} style={{ cursor: "pointer" }} onClick={() => handleUserClick(item.code)}>
                  <Typography variant="h6" className="whiteText" style={{ fontWeight: 100, fontSize: matchesLessThan600 ? 14 : 16 }}>
                    {item.nombre}
                  </Typography>
                </Grid>
                <Grid container alignItems="center" justify="center" item xs={2}>
                  <Checkbox onChange={() => handleUserClick(item.code)} checked={item.isChecked} />
                </Grid>
              </Grid>
            </div>
          )
        })
      }
    </div>
  )
}

export default UserSection;
