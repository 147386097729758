import React from "react";
import ConceptsFormulas from "./conceptsFormulas/conceptsFormulas";
import Functions from "./functions/functions";
import paths from "@icarius-localization/paths";
import { useLocation } from "react-router-dom";

const ConceptsFormulasFunctions = ({ history }) => {

  const location = useLocation();

  const handleGoBack = () => history.push(paths.conceptsAndFormulasScreenSelector);

  const getScreenToShow = () => {
    if (location.pathname === paths.calculationsFunctions) {
      return (
        <Functions handleGoBack={handleGoBack} />
      )
    }

    if (location.pathname === paths.conceptsAndFormulas) {
      return (
        <ConceptsFormulas handleGoBack={handleGoBack} />
      )
    }
  }

  return (
    <section id={"my-people-page"}>
      {
        getScreenToShow()
      }
    </section>
  );
}

export default ConceptsFormulasFunctions;
