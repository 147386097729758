import { useState } from "react";

const useHandleConceptsFormulasForm = (
  data,
  initialFormula,
  personalizedFormula,
  esFormulaPersonalizada,
  maxSeq,
  familiaArr,
  clasificacionArr,
  disponibilidadArr,
  createCallback,
  modifyCallback,
  invalidDataCallback,
  invalidCodeCallback,
  openNoFormulaDialog,
) => {

  const isCreate = !Boolean(data);

  const getBooleanValue = (data) => {
    return data === "SI" || data === "Y";
  }

  const getSelectKeyValue = (data, arr) => {
    return arr.filter(item => item.value === data)[0]?.key || '';
  }

  const [code, setCode] = useState(isCreate ? "" : data["Código de concepto"]);
  const [name, setName] = useState(isCreate ? "" : data["Nombre del concepto"]);
  const [sequence, setSequence] = useState(isCreate ? (maxSeq + 1) : data["Secuencia de cálculo"]);
  const [family, setFamily] = useState(isCreate ? "" : getSelectKeyValue(data["Familia"], familiaArr));
  const [classification, setClassification] = useState(isCreate ? "" : getSelectKeyValue(data["Clasificación"], clasificacionArr));
  const [discount, setDiscount] = useState(isCreate ? "" : data["discount"]);
  const [id, setID] = useState(isCreate ? "" : data["ID"]);
  const [availability, setAvailability] = useState(isCreate ? "" : getSelectKeyValue(data["Disponibilidad"], disponibilidadArr));
  const [formula, setFormula] = useState(isCreate ? "" : initialFormula);
  const [formulaPersonalizada, setFormulaPersonalizada] = useState(isCreate ? "" : personalizedFormula);
  const [usePersonalizedFormula, setUsePersonalizedFormula] = useState(isCreate ? true : getBooleanValue(esFormulaPersonalizada));
  const [help, setHelp] = useState(isCreate ? "" : data["Ayuda para el ingreso"]);
  const [attachment, setAttachment] = useState(isCreate ? null : data["Archivo"]);

  const formData = {
    code,
    name,
    sequence,
    family,
    classification,
    id,
    availability,
    formula,
    formulaPersonalizada,
    usePersonalizedFormula,
    help,
    discount,
    attachment,
  };

  const codeIsValid = () => {
    const codeRegex = /^([A-Z]|\d|_|-){0,8}$/;

    if (!code || code === "" || !codeRegex.test(code)) return false;
    return true;
  }

  const dataIsValid = () => {
    if (!name || name === "") return false;
    if (!sequence || sequence === "") return false;
    if (!family || family === "") return false;
    if (family !== "O" && (!classification || classification === "")) return false;
    if (!availability || availability === "") return false;

    return true;
  }

  const submit = () => {
    if (!codeIsValid()) {
      invalidCodeCallback();
      return false;
    }

    const isValid = dataIsValid();
    if (isValid) {
      let dataToSend = {
        "Código de concepto": code,
        "Nombre del concepto": name,
        "Secuencia de cálculo": sequence,
        "Familia": family,
        "Clasificación": classification,
        "Disponibilidad": availability,
        "ID": id,
        "Fórmula": formula,
        "Fórmula Personalizada": formulaPersonalizada,
        "Es Fórmula Personalizada": usePersonalizedFormula ? "Y" : "N",
        "Ayuda para el ingreso": help,
        discount,
        "Archivo": attachment,
      };
      isCreate ? createCallback(dataToSend) : modifyCallback(dataToSend);
      return true;
    } else {
      if (isValid === 0) {
        openNoFormulaDialog();
      } else {
        invalidDataCallback();
      }
      return false;
    }
  }

  const setFormValue = (value, fieldName) => {
    switch (fieldName) {
      case "code":
        setCode(value?.length > 0 ? value.replace(/\s/g, "").toUpperCase() : "");
        break;
      case "name":
        setName(value);
        break;
      case "sequence":
        setSequence(value?.length > 0 ? value.replace(/\D/g, '').substring(0, 4) : "");
        break;
      case "family":
        setFamily(value);
        break;
      case "classification":
        setClassification(value);
        break;
      case "id":
        setID(value);
        break;
      case "availability":
        setAvailability(value);
        break;
      case "formula":
        setFormula(value);
        break;
      case "formulaPersonalizada":
        setFormulaPersonalizada(value);
        break;
      case "usePersonalizedFormula":
        setUsePersonalizedFormula(value);
        break;
      case "help":
        setHelp(value);
        break;
      case "discount":
        setDiscount(value);
        break;
      case "attachment":
        setAttachment(value);
        break;
      default: break;
    }
  }

  return { isCreate, formData, setFormValue, submit };
}

export default useHandleConceptsFormulasForm;
