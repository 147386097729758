import * as actionTypes from "./actionTypes";

const initialState = {
  rows: [],
  loading: false,
  codeRefTypes: [],
  columnTypes: [],
  calculationIndicatorTypes: [],
  informedValueTypeTypes: [],
  concepts: [],
  entryModeTypes: [],
};

const clearRows = rows => {
  // Saco los guiones
  const newRows = rows.map(row => Object.keys(row)
    .reduce((obj, key) => {
      obj[key] = row[key] === "-" ? '' : row[key];
      return obj;
    }, {})
  )

  return newRows;
}

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ROWS:
      return { ...state, loading: true };
    case actionTypes.GET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: clearRows(action.payload.rows),
        codeRefTypes: action.payload.codeRefTypes,
        columnTypes: action.payload.columnTypes,
        calculationIndicatorTypes: action.payload.calculationIndicatorTypes,
        informedValueTypeTypes: action.payload.informedValueTypeTypes,
        entryModeTypes: action.payload.entryModeTypes,
        concepts: action.payload.concepts,
      };
    case actionTypes.GET_ROWS_REJECTED:
      return { ...state, loading: false };
    case actionTypes.SET_ROWS:
      return { ...state, loading: true };
    case actionTypes.SET_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        rows: clearRows(action.payload.rows),
      };
    case actionTypes.SET_ROWS_REJECTED:
      return { ...state, loading: false };
    case actionTypes.DELETE_ROWS:
      return { ...state, loading: true };
    case actionTypes.DELETE_ROWS_FULFILLED:
      return {
        ...state,
        loading: false,
        // rows: clearRows(action.payload.rows),
      };
    case actionTypes.DELETE_ROWS_REJECTED:
      return { ...state, loading: false };
    default:
      return state;
  }
}
