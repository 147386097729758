import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getLocalizedString } from "@icarius-localization/strings";
import { getLoading, getRows, getConcepts, getTypes } from "../selectors";
import { Grid } from "@material-ui/core";
import { getConceptGroupsAction, getConceptGroupsDetailsAction, getConceptsSetConceptsAction } from "../actions";
import * as actionTypes from "../actionTypes";
import ABMLayout from "@icarius-common/abmComponents/abmLayout";
import DataItem from "./data/dataItem";
import paths from "@icarius-localization/paths";
import DuplicateDialog from "./dialogs/duplicateDialog";

const ConceptsSetSettings = ({ history }) => {

  const [createIsOpen, setCreateIsOpen] = useState(false);
  const [duplicateDialogIsOpen, setDuplicateDialogIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const isLoading = useSelector(getLoading);
  const rows = useSelector(getRows);
  const dispatch = useDispatch();
  const concepts = useSelector(getConcepts);
  const types = useSelector(getTypes);

  useEffect(() => {
    dispatch(getConceptGroupsAction()).then(resp => dispatch(getConceptGroupsDetailsAction()));
    dispatch(getConceptsSetConceptsAction());
    return () => dispatch({ type: actionTypes.RESET_STATE })
  }, [dispatch])

  const getDataIsEmpty = () => {
    return !rows || rows.length === 0;
  }

  const handleGoBack = () => {
    history.push(paths.conceptsAndFormulasScreenSelector);
  }

  const handleOpenDuplicateDialog = (selected) => {
    setDuplicateDialogIsOpen(true);
    setSelectedItem(selected);
  };

  const handleCloseDuplicateDialog = () => {
    setDuplicateDialogIsOpen(false);
    setSelectedItem(null);
  }

  return (
    <ABMLayout
      title={getLocalizedString("conceptsSetSettingsTitle")}
      isLoading={isLoading}
      showNoData={getDataIsEmpty()}
      noDataTitle={'No se encontraron conjuntos de conceptos'}
      noDataCreateTitle={"Nuevo conjunto de conceptos"}
      noDataOnClick={() => setCreateIsOpen(true)}
      showCreateButton={!createIsOpen}
      createButtonTitle={"Nuevo conjunto de conceptos"}
      createButtonOnClick={() => setCreateIsOpen(true)}
      showGoBack
      handleGoBack={handleGoBack}
    >
      {
        createIsOpen &&
        <Grid container item xs={12} style={{ margin: "10px 0px" }}>
          <DataItem
            data={null}
            concepts={concepts}
            types={types}
            rowsToUse={rows}
            closeNew={() => setCreateIsOpen(false)}
          />
        </Grid>
      }
      {
        rows && rows.map((item) => {
          return (
            <Grid container item xs={12} style={{ margin: "10px 0px" }} key={item.code}>
              <DataItem
                data={item}
                types={types}
                concepts={concepts}
                rowsToUse={rows}
                handleOpenDuplicateDialog={handleOpenDuplicateDialog}
              />
            </Grid>
          )
        })
      }
      {
        duplicateDialogIsOpen && !isLoading &&
        <DuplicateDialog
          open={duplicateDialogIsOpen}
          data={selectedItem}
          handleClose={handleCloseDuplicateDialog}
        />
      }
    </ABMLayout>
  );
}

export default ConceptsSetSettings;
