import React from "react";
import { CloseIcon } from "@icarius-icons";
import {
  Typography,
  Grid,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@material-ui/core";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import DialogTransition from "@icarius-common/dialogTransition";

const StructuresDialog = (props) => {

  const { open, data, handleClose } = props;

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      scroll={"paper"}
      maxWidth={"md"}
      fullWidth
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {`Estructuras de ingreso del concepto: ${data['Nombre del concepto']}`}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container item xs={12} direction="row" style={{ maxHeight: 500 }}>
          {
            data.structuresAssociated.map((structure, index) => {
              return (
                <Grid key={index} container alignItems='center' item xs={12} style={{ height: 40 }}>
                  <Typography className="whiteText" style={{ fontSize: "18px", fontWeight: 400 }}>
                    {structure}
                  </Typography>
                </Grid>
              )
            })
          }
          {
            !Boolean(data.structuresAssociated.length) &&
            <Grid container alignItems='center' justify="center" item xs={12} style={{ height: 40 }}>
              <Typography className="whiteText" style={{ fontSize: "24px", fontWeight: 400 }}>
                {'No se encontraron estructuras de ingreso'}
              </Typography>
            </Grid>
          }
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default StructuresDialog;
