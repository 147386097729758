import paths from "@icarius-localization/paths";

export default [
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Código",
            field: "code",
            isObligatory: true,
            pattern: /^[A-Z]+[A-Z0-9_]*$/,
            maxLength: 12,
            minLength: 1,
            isFixed: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Nombre",
            field: "name",
            isObligatory: true,
            maxLength: 254,
            minLength: 1,
            isFixed: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Periodicidad",
            field: "periodicity",
            isObligatory: true,
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Horas y Minutos",
            field: "hoursMinutes",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Cantidad",
            field: "quantity",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Porcentaje",
            field: "percentage",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Base de cálculo",
            field: "calculationBase",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Datos para reliquidación",
            field: "reliquidation",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Honorarios",
            field: "honoraries",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Pago a tercero",
            field: "thirdPartyPayment",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Período de imputación",
            field: "imputationPeriod",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Centro de costo",
            field: "benefitCenter",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Proyecto",
            field: "project",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Código de referencia",
            field: "codeRef",
            isSelect: true,
            isMultipleSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Anticipo",
            field: "advancePayment",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Indicador de cálculo",
            field: "calculationIndicator",
            isSelect: true,
            isMultipleSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Valor informado",
            field: "informedValue",
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Tipo de valor informado",
            field: "informedValueType",
            isObligatory: false, //** Si “Valor informado” es O o B, este campo de “Tipo de valor informado” debe ser obligatorio y solo permitir seleccionar L o B.
            isSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Conceptos",
            field: "concepts",
            isSelect: true,
            isMultipleSelect: true,
        }
    },
    {
        pages: [paths.conceptStructure],
        config: {
            headerName: "Modalidad de ingreso",
            field: "entryMode",
            isSelect: true,
            isObligatory: true,
            isMultipleSelect: true,
        }
    },
]