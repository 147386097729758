import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getLocalizedString } from "@icarius-localization/strings";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getConceptGroupsAPI,
  createConceptGroupsAPI,
  updateConceptGroupsAPI,
  deleteConceptGroupsAPI
} from "@icarius-connection/api";

export const getConceptGroupsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getConceptGroupsAPI();

    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const createConceptGroupsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createConceptGroupsAPI(data);

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });
    dispatch(openSnackbarAction({ msg: 'Concepto creado con éxito', severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir grupos con el mismo nombre", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    return e.response;
  }
};

export const updateConceptGroupsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateConceptGroupsAPI(data);

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });
    dispatch(openSnackbarAction({ msg: 'Concepto actualizado con éxito', severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DATA_IN_USE") {
      dispatch(openSnackbarAction({ msg: getLocalizedString("conceptsInUse"), severity: "error" }));
    } else {
      if (e.response.data.status === "DUPLICATED_DATA") {
        dispatch(openSnackbarAction({ msg: "No pueden existir grupos con el mismo nombre", severity: "error" }));
      } else {
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }
    }
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    return e.response;
  }
};

export const deleteConceptGroupsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let response = await deleteConceptGroupsAPI(data);

    dispatch({
      type: actionTypes.DELETE_FULFILLED,
      payload: {
        rows: response.data.result,
      }
    });
    dispatch(openSnackbarAction({ msg: 'Concepto eliminado con éxito', severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DATA_IN_USE") {
      dispatch(openSnackbarAction({ msg: 'No se puede eliminar el concepto por estar en uso', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;
  }
};