import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getFunctionRowsAction, deleteFunctionAction } from "../../actions";
import { getFunctionsGridData, getLoading } from "../../selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import Form from "./form/form";
import SearchFunctions from "./searchFunctions";
import DuplicateDialog from "./duplicateDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import { openDialogAction } from "@icarius-common/dialog/actions";

const Functions = ({ handleGoBack }) => {

  const [selectedFunction, setSelectedFunction] = useState(null);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);

  const dispatch = useDispatch();
  const exportingGridStatus = useSelector(getIsExportingGrid);

  const gridData = useSelector(getFunctionsGridData);
  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getFunctionRowsAction());
  }, [dispatch])

  const handleDelete = (functionToDelete) => {
    const dataToSend = {
      name: functionToDelete.name,
      description: functionToDelete.description,
      type: functionToDelete.typeId,
    }

    dispatch(deleteFunctionAction(dataToSend))
  }

  const handleOpenDelete = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una función", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar función",
      msg: "¿Desea eliminar la función?",
      onConfirm: () => handleDelete(selectedRows[0]),
    }));
  };

  const handleOpenDuplicate = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar una función", severity: "warning" }));
      return;
    }

    setSelectedFunction(selectedRows[0]);
    setDuplicateIsOpen(true);
  };

  const handleCloseDuplicate = () => {
    setSelectedFunction(null);
    setDuplicateIsOpen(false);
  }

  const handleOpenForm = (row) => {
    const data = row?.data || null;
    setSelectedFunction(data);
    setFormIsOpen(true);
  };

  const handleCloseForm = () => {
    setFormIsOpen(false);
    setSelectedFunction(null);
  }

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Nueva función"}
      onClick={() => handleOpenForm()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDelete(gridRef)}
      type={"delete"}
    />

  const duplicateItem = (gridRef) =>
    <CustomIconButton
      title={"Duplicar función"}
      onClick={() => handleOpenDuplicate(gridRef)}
      type={"duplicate"}
    />

  const searchItem = (ref) => <SearchFunctions gridRef={ref} />

  return (
    <>
      {
        Boolean(formIsOpen) ?
          <Form data={selectedFunction} handleClose={handleCloseForm} />
          :
          <CommonPage
            columnDefPage={paths.calculationsFunctions}
            title={"Funciones de cálculo"}
            gridTitle={"Funciones de cálculo"}
            menuItems={[goBackItem, searchItem, addItem, duplicateItem, deleteItem]}
            rowData={gridData}
            handleRowClick={handleOpenForm}
            isLoading={loading || exportingGridStatus}
            hasHelp
            hasExpand
            hasSelectAll
          >
            {
              duplicateIsOpen && !loading &&
              <DuplicateDialog
                open={duplicateIsOpen}
                data={selectedFunction}
                handleClose={handleCloseDuplicate}
              />
            }
          </CommonPage>
      }
    </>
  );
}

export default Functions;
