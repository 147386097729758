import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CloseIcon } from "@icarius-icons";
import { Dialog, DialogTitle, DialogContent, Grid, DialogActions, useMediaQuery } from "@material-ui/core";
import { getLocalizedString } from "@icarius-localization/strings";
import DialogTitleDivider from "@icarius-common/dialogTitleDivider";
import PaperDraggable from "@icarius-common/paperDraggable";
import ButtonDialogAction from "@icarius-common/buttonDialogAction";
import { getAppColor } from "src/app/selectors";
import UserSelectionColumn from "./column";
import DialogTransition from "@icarius-common/dialogTransition";

const useHandleUserSelection = (concepts, types, typesPreselected, preselected, useSingleSelection) => {
  const [keysSelected, setKeysSelected] = useState(preselected);
  const [keyTypesValuesSelected, setKeyTypesValuesSelected] = useState(typesPreselected);
  const [checkedConcepts, setCheckedConcepts] = useState([]);
  const [checkedTypesValues, setCheckedTypesValues] = useState([]);
  const [checkedList, setCheckedList] = useState(concepts);

  const deleteElementFromArray = (arr, element) => {
    const auxArr = [...arr];
    const indexToDelete = auxArr.indexOf(element);
    if (indexToDelete > -1) auxArr.splice(indexToDelete, 1);
    return auxArr;
  }

  const handleClickUser = (key) => {
    if (key.constructor === Array) {
      const cleanKeys = key.map(item => item.code);
      setKeysSelected(cleanKeys);
      // setCheckedConcepts(key)
    } else {
      let auxKeysSelected = [...keysSelected];
      let auxUserTypesSelected = [...keyTypesValuesSelected];

      //si ya esta en el array borrarlo
      if (auxKeysSelected.includes(key)) {
        const index = auxKeysSelected.indexOf(key);
        auxKeysSelected = deleteElementFromArray(auxKeysSelected, key);
        auxUserTypesSelected = deleteElementFromArray(auxUserTypesSelected, index);
      } else { //sino, agregarlo al final del array, o dejar ese elemento solo (si es seleccion simple)
        if (useSingleSelection) auxKeysSelected = [key];
        else {
          auxKeysSelected.push(key);
          auxUserTypesSelected.push("V");
        }
      }

      setKeysSelected(auxKeysSelected);
      setKeyTypesValuesSelected(auxUserTypesSelected);
    }
  }

  useEffect(() => { //con cada cambio en keysSelected, modificar checkedList con el user correspondiente
    const newCheckedList = concepts.map(user => {
      return {
        ...user,
        isChecked: keysSelected.includes(user.code),
      }
    });

    const resultadoFiltrado = newCheckedList.filter(user => keysSelected.includes(user.code));

    const resultadoOrdenado = resultadoFiltrado.sort(function sortFunc(a, b) {
      return keysSelected.indexOf(a.code) - keysSelected.indexOf(b.code);
    })

    const newTypeValueList = [];
    keyTypesValuesSelected.forEach(key => {
      const item = types.find(type => type.key === key);
      if (item) newTypeValueList.push(item);
    })

    setCheckedConcepts(resultadoOrdenado);
    setCheckedList(newCheckedList);
    setCheckedTypesValues(newTypeValueList);
  }, [concepts, types, keysSelected, keyTypesValuesSelected])

  return [checkedConcepts, checkedTypesValues, checkedList, handleClickUser];
}

const UserSelectionDialog = (props) => {

  const {
    open,
    useSingleSelection, //bool indicando si es para seleccionar de a uno o varios
    concepts, //array de objetos con key, name y FOTOGRAFIA
    preselected, //array de strings con key del primero seleccionado, si no hay, [] o [""]
    title,
    firstColumnTitle,
    handleClose,
    handleSubmit,
    showAmount, //bool indicando si en la primera columna se indica la cantidad seleccionada
    filterPlaceholderText, //texto opcional para el placeholder del input
    types,
    typesPreselected,
  } = props;

  const [checkedConcepts, checkedTypesValues, checkedList, handleClickUser] = useHandleUserSelection(concepts, types, typesPreselected, preselected, useSingleSelection);

  const [selectedOrderedElements, setSelectedOrderedElements] = useState([]);
  const [selectTypeElements, setSelectTypeElements] = useState([]);

  const handleSubmitUsers = () => {
    handleClickUser(selectedOrderedElements);
    handleSubmit(selectedOrderedElements.map(item => item.code), selectTypeElements.map(item => item.key));
    handleClose();
  }

  const color = useSelector(getAppColor);
  const matchesMoreOrEqualThan960 = useMediaQuery(`(min-width:960px)`);

  return (
    <Dialog
      TransitionComponent={DialogTransition}
      PaperComponent={PaperDraggable}
      open={open}
      fullWidth
      maxWidth={matchesMoreOrEqualThan960 ? "md" : "sm"}>
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title}
        <DialogTitleDivider />
      </DialogTitle>
      <CloseIcon className={"dialog-close-icon icon"} onClick={handleClose} />
      <DialogContent>
        <Grid container direction="row" justify="space-around">
          {/* Columna 1 - seleccionados */}
          <UserSelectionColumn
            isActualMembersColumn
            firstColumnTitle={firstColumnTitle}
            color={color}
            concepts={checkedConcepts}
            types={checkedTypesValues}
            handleUserClick={(key) => handleClickUser(key)}
            showAmount={showAmount}
            setSelectedOrderedElements={setSelectedOrderedElements}
            setSelectTypeElements={setSelectTypeElements}
            typesOptions={types}
          />
          {/* Columna 2 - todos los usuarios */}
          <UserSelectionColumn
            color={color}
            concepts={checkedList}
            handleUserClick={(key) => handleClickUser(key)}
            filterPlaceholderText={filterPlaceholderText}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <ButtonDialogAction onClick={handleClose} text={getLocalizedString("disagree")} />
        <ButtonDialogAction onClick={handleSubmitUsers} isAccept text={getLocalizedString("accept")} />
      </DialogActions>
    </Dialog>
  );
}

export default UserSelectionDialog;
