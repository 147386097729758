import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
    getConceptStructureAPI,
    setConceptStructureAPI,
    deleteConceptStructureAPI,
    importStructuresAPI,
} from "@icarius-connection/api";

const errorHandler = (e, dispatch) => {
    let errorString = "";
    switch (e.response?.data?.status) {
        case "IN_USE":
        case "IN_USE_MODULES":
            errorString = "No es posible eliminar las estructuras seleccionadas porque están en uso";
            break;
        case "IN_USE_ASSETSDISCOUNTS":
            errorString = "No es posible eliminar las estructuras porque tienen haberes o descuentos informados en ella";
            break;
        default:
            errorString = getErrorStringFromError(e);
            break;
    }
    dispatch(openSnackbarAction({ msg: errorString, severity: "error", duration: 5000 }));
}

export const getConceptStructureFromServer = () => async (dispatch) => {
    dispatch({ type: actionTypes.GET_ROWS });
    try {
        let response = await getConceptStructureAPI();
        let rows = response.data && response.data.data;
        let codeRefTypes = response.data && response.data.codeRefTypes;
        let columnTypes = response.data && response.data.columnTypes;
        let calculationIndicatorTypes = response.data && response.data.calculationIndicatorTypes;
        let concepts = response.data && response.data.concepts;
        let informedValueTypeTypes = response.data && response.data.informedValueTypeTypes;
        let entryModeTypes = response.data && response.data.entryModeTypes;

        dispatch({
            type: actionTypes.GET_ROWS_FULFILLED,
            payload: { rows, codeRefTypes, columnTypes, calculationIndicatorTypes, informedValueTypeTypes, concepts, entryModeTypes },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.GET_ROWS_REJECTED, payload: e });
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const importStructuresAction = (dataToSend) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let formData = new FormData();
        formData.append('file', dataToSend.file);

        let response = await importStructuresAPI(formData);
        let importNotPossibleElements = response.data && response.data.importNotPossibleElements;
        let rows = response.data && response.data.rows;

        if (importNotPossibleElements.length > 0) {
            dispatch(openSnackbarAction({
                msg: 'Las siguientes estructuras no se pueden importar ya que tienen datos en el destino: ' + importNotPossibleElements.join(", "),
                severity: "warning",
                duration: null
            }));
        } else {
            dispatch(openSnackbarAction({ msg: 'Estructura de ingreso de conceptos importada con éxito', severity: "success" }));
        }

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
            payload: { rows },
        });

        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir estructuras con el mismo código o nombre", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const setConceptStructureToServer = (data) => async (dispatch) => {
    dispatch({ type: actionTypes.SET_ROWS });
    try {
        let response = await setConceptStructureAPI({ data });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.SET_ROWS_FULFILLED,
            payload: { rows },
        });
        dispatch(openSnackbarAction({ msg: 'Estructura de ingreso de conceptos actualizada con éxito', severity: "success" }));
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.SET_ROWS_REJECTED, payload: e });
        if (e.response.data.status === 'DUPLICATED_DATA') {
            dispatch(openSnackbarAction({ msg: "No pueden existir estructuras con el mismo código o nombre", severity: "error" }));
            return e;
        }
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
};

export const deleteConceptStructureInServer = (codes) => async (dispatch) => {
    dispatch({ type: actionTypes.DELETE_ROWS });
    try {
        let response = await deleteConceptStructureAPI({ codes });
        let rows = response.data && response.data.data;

        dispatch({
            type: actionTypes.DELETE_ROWS_FULFILLED,
            payload: { rows },
        });
        return response;
    } catch (e) {
        dispatch({ type: actionTypes.DELETE_ROWS_REJECTED, payload: e });
        errorHandler(e, dispatch);
        return e;
    }
};