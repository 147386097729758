import React, { useRef, useEffect, useState } from "react";
import CommonPage from "@icarius-common/commonPage";
import EditableGridv2 from "@icarius-common/editableGrid/editableGridv2";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import AddRowStatusBar from '@icarius-common/editableGrid/Components/addRow/addRowButton';
import paths from "@icarius-localization/paths";
import { getLocalizedString } from "@icarius-localization/strings";
import { getConceptStructureFromServer, setConceptStructureToServer, deleteConceptStructureInServer } from "../actions";
import {
  getRows, getLoading, getCodeRefTypes, getColumnTypes, getCalculationIndicatorTypes,
  getInformedValueTypeTypes, getConcepts, getEntryModeTypes
} from "../selectors";
import { useDispatch, useSelector } from "react-redux";
import { getColumnDefByPage } from "@icarius-table/columnDefs";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import ConfirmationDialog from "./confirmationDialog";
import { Workbook } from 'exceljs';
import { saveAs } from 'file-saver';
import { exportDataGrid } from "devextreme/excel_exporter"
import UploadFileDialog from "./uploadFileDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";

const dummyEntity = {
  "type": "CON",
  "periodicity": "F",
  "hoursMinutes": "",
  "quantity": "",
  "percentage": "",
  "calculationBase": "",
  "reliquidation": "",
  "honoraries": "",
  "thirdPartyPayment": "",
  "imputationPeriod": "",
  "benefitCenter": "",
  "project": "",
  "codeRef": [],
  "advancePayment": "",
  "calculationIndicator": [],
  "entryMode": [],
  "concepts": [],
  "informedValue": "",
  "informedValueType": ""
};

const ConceptStructure = ({ history }) => {

  const [dataChanged, setDataChanged] = useState(false);
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = useState(false);
  const [shouldReloadGrid, setShouldReloadGrid] = useState(false);
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = useState(false);
  const [gridReference, setGridReference] = useState(null);

  const dispatch = useDispatch();
  const codeRefTypes = useSelector(getCodeRefTypes);
  const columnTypes = useSelector(getColumnTypes);
  const calculationIndicatorTypes = useSelector(getCalculationIndicatorTypes);
  const informedValueTypeTypes = useSelector(getInformedValueTypeTypes);
  const entryModeTypes = useSelector(getEntryModeTypes);
  const concepts = useSelector(getConcepts);

  const rowData = useSelector(getRows);
  const isLoading = useSelector(getLoading);

  const gridRef = useRef();

  useEffect(() => {
    dispatch(getConceptStructureFromServer());
    window.scrollTo(0, 0);
  }, [dispatch])

  useEffect(() => {
    if (gridRef) {
      setGridReference(gridRef.current.refs.dataGrid._instance);
    }
  }, [gridRef])

  const handleSave = () => {
    const rowData = gridReference.getVisibleRows().map(item => item.data);
    const formattedData = rowData.map(row => {
      //Completo los campos faltantes
      row = { ...dummyEntity, ...row };

      const filtered = Object.keys(row)
        .filter(key => !key.includes("String"))
        .reduce((obj, key) => {
          obj[key] = row[key];
          return obj;
        }, {});

      filtered.calculationIndicator = filtered.calculationIndicator.filter(item => item).map(item => item.key ? item.key : item);
      filtered.codeRef = filtered.codeRef.filter(item => item).map(item => item.key ? item.key : item)
      filtered.entryMode = filtered.entryMode.filter(item => item).map(item => item.key ? item.key : item)
      filtered.concepts = filtered.concepts.filter(item => item).map(item => item.key ? item.key : item)

      return filtered;
    })

    dispatch(setConceptStructureToServer(formattedData))
      .then((resp) => {
        //limpiar el seleccionado
        gridReference.deselectAll()

        if (resp?.response?.data?.status === 'DUPLICATED_DATA') {
          const rowsToSelect = [];
          resp.response.data.fields.forEach(field => {
            const keysToSelect = resp.response.data[`${field}s`];
            gridReference.getVisibleRows().forEach(node => {
              if (keysToSelect.includes(node.data[field])) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          })
        } else {
          setShouldReloadGrid(true);
        }

        if (resp?.status === 200) {
          setDataChanged(false);
        }
      })
  };


  const handleOpenDeleteDialog = () => {
    // Me fijo cuantas filas seleccionadas tengo
    let selectedRows = gridReference.getSelectedRowsData();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length < 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar al menos una fila", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: getLocalizedString("atention"),
      msg: '¿Desea eliminar las filas seleccionadas?',
      onConfirm: () => deleteRowsFromServer(selectedRows),
    }));
  }

  const deleteRowsLocally = (rowsToDelete) => {
    gridReference.cancelEditData();
    if (Array.isArray(rowsToDelete)) {
      const ds = gridReference.getDataSource();
      const filter = gridReference.getCombinedFilter();
      ds.store().load({ sort: ds.sort(), filter: filter ? filter : null })
        .done((allData) => {
          const rowData = allData;
          rowsToDelete.forEach(row => {
            const filteredRows = rowData.filter(el => JSON.stringify(el) === JSON.stringify(row));
            filteredRows.forEach(gridRow =>
              gridReference.getDataSource().store().remove(gridRow).then(() => {
                gridReference.refresh();
              })
            )
          });
        });
    }
    dispatch(openSnackbarAction({ msg: 'Estructuras eliminadas con éxito', severity: "success" }));
  }

  const deleteRowsFromServer = (rowsToDelete) => {
    const codesToSend = rowsToDelete.filter(row => !row.hasOwnProperty("__KEY__")).map(row => row.code);

    if (codesToSend.length > 0) {
      dispatch(deleteConceptStructureInServer(codesToSend))
        .then((resp) => {
          //limpiar el seleccionado
          gridReference.deselectAll();
          if (['IN_USE_MODULES', 'IN_USE', 'IN_USE_ASSETSDISCOUNTS'].includes(resp?.response?.data?.status)) {
            const keysToSelect = resp.response.data.dependencies;
            const rowsToSelect = [];
            gridReference.getVisibleRows().forEach(node => {
              if (keysToSelect.includes(node.data.code)) {
                rowsToSelect.push(node.key);
              }
            });

            gridReference.selectRows(rowsToSelect);
          } else {
            deleteRowsLocally(rowsToDelete);
          }
        })
    } else if (rowsToDelete.length > codesToSend.length) {
      deleteRowsLocally(rowsToDelete);
    }
  }

  const handleImportStructures = () => {
    setUploadDialogIsOpen(true);
  };

  const handleGoBack = () => {
    history.push(paths.conceptsAndFormulasScreenSelector);
  }

  const handleCheckGoBack = () => {
    if (dataChanged) {
      setConfirmationDialogIsOpen(true);
    } else {
      handleGoBack();
    }
  }

  const handleExportGrid = () => {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Estructura de ingreso conceptos');

    exportDataGrid({
      component: gridReference,
      worksheet,
      autoFilterEnabled: true,
      customizeCell: ({ gridCell, excelCell }) => {
        if (gridCell.value) {
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField === 'num' && gridCell.value !== "") {
              excelCell.value = parseFloat(gridCell.value);
              excelCell.numFmt = '_(#,##0.000_);_((#,##0.000)';
            }
          }
        }
      },
    }).then(() => {
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Estructura de ingreso de conceptos.xlsx');
      });
    });
  }

  const saveItem = () => (
    <CustomIconButton
      title={"Guardar"}
      onClick={handleSave}
      type={"save"}
    />
  )

  const importItem = () => (
    <CustomIconButton
      title={"Importar estructuras"}
      onClick={handleImportStructures}
      type={"upload"}
    />
  )

  const deleteItem = () => (
    <CustomIconButton
      title={"Eliminar"}
      onClick={handleOpenDeleteDialog}
      type={"delete"}
    />
  )

  const addRowButton = () => (
    <AddRowStatusBar {...gridRef.current} />
  )

  const goBackButton = () => (
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleCheckGoBack}
      type={"goBack"}
    />
  )

  const exportGrid = () => (
    <CustomIconButton
      title={"Exportar"}
      onClick={handleExportGrid}
      type={"excel"}
    />
  )

  const selectDataSources = name => {
    switch (name) {
      case "periodicity":
        return columnTypes.filter(item => item.key === "P" || item.key === "T" || item.key === "F" || item.key === "M" || item.key === "C" || item.key === "CP");
      case "hoursMinutes":
      case "quantity":
      case "percentage":
      case "calculationBase":
      case "reliquidation":
      case "imputationPeriod":
      case "benefitCenter":
      case "project":
      case "informedValue":
        return columnTypes.filter(item => item.key === "O" || item.key === "B");
      case "honoraries":
      case "advancePayment":
      case "thirdPartyPayment":
        return columnTypes.filter(item => item.key === "B");
      case "codeRef":
        return codeRefTypes;
      case "calculationIndicator":
        return calculationIndicatorTypes;
      case "informedValueType":
        return informedValueTypeTypes;
      case "entryMode":
        return entryModeTypes;
      case "concepts":
        return concepts;
      default:
        return [];
    }
  }

  const formattedData = rowData.map(row => {
    return {
      ...row,
      codeRef: row.codeRef.length ? row.codeRef : [],
      calculationIndicator: row.calculationIndicator.length ? row.calculationIndicator : [],
      entryMode: row.entryMode.length ? row.entryMode : [],
      concepts: row.concepts.length ? row.concepts : [],
    }
  })

  useEffect(() => {
    rowData && setShouldReloadGrid(true);
  }, [rowData])

  return (
    <CommonPage
      title={'Estructura de ingreso de conceptos'}
      menuItems={[goBackButton, addRowButton, saveItem, exportGrid, importItem, deleteItem]}
      isLoading={isLoading}
      isNotGridPage
    >
      <EditableGridv2
        selectDataSources={selectDataSources}
        setDataChanged={setDataChanged}
        ref={gridRef}
        gridName="conceptStructure"
        rowData={formattedData}
        setShouldReloadGrid={setShouldReloadGrid}
        shouldReloadGrid={shouldReloadGrid}
        columns={getColumnDefByPage(paths.conceptStructure)}
      />
      {
        confirmationDialogIsOpen &&
        <ConfirmationDialog
          open={confirmationDialogIsOpen}
          handleConfirm={handleGoBack}
          handleClose={() => setConfirmationDialogIsOpen(false)}
        />
      }
      {uploadDialogIsOpen &&
        <UploadFileDialog
          open={uploadDialogIsOpen}
          isLoading={isLoading}
          handleClose={() => setUploadDialogIsOpen(false)}
        />
      }
    </CommonPage>
  );
}

export default ConceptStructure;
