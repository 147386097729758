import { useState } from "react";
import * as yup from 'yup';

const useHandleForm = (duplicateCallback, invalidDataCallback) => {

  const createInitialData = () => {

    const getInitialFieldData = () => {
      return "";
    }

    const fieldNames = ["code", "name"];

    return fieldNames.reduce((initialData, fieldName) => {
      return ({
        ...initialData,
        [fieldName]: getInitialFieldData()
      })
    }, {});
  }

  const [formData, setFormData] = useState(createInitialData());

  const dataIsValid = async () => {
    const regex = /^[(A-Z)]([A-Za-z]|\d|_)+$/;

    const schema = yup.object().shape({
      code: yup.string().max(12).matches(regex).required(),
      name: yup.string().max(100).required(),
    });

    return await schema.isValid(formData).then((valid) => valid);
  }

  const submit = async () => {
    if (await dataIsValid()) {
      let dataToSend = {
        code: formData.code,
        name: formData.name,
      };

      duplicateCallback(dataToSend);
      return true;
    } else {
      invalidDataCallback();
      return false;
    }
  }


  const setFormValue = (value, fieldName) => {   
    setFormData({
      ...formData,
      [fieldName]: value
    })
  }

  return { formData, setFormValue, submit };
}

export default useHandleForm;
