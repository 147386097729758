import * as actionTypes from "./actionTypes";

const initialState = {
  isLoading: false,
  isLoadingConcepts: false,
  rows: [],
  types: [],
  setTypes: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET:
      return { ...state, isLoading: true };
    case actionTypes.GET_FULFILLED:
      return {
        ...state,
        // isLoading: false,
        rows: action.payload.rows,
        setTypes: action.payload.setTypes,
      };
    case actionTypes.GET_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_DETAILS:
      return { ...state, isLoading: true };
    case actionTypes.GET_DETAILS_FULFILLED: {
      const rowsWithConcepts = state.rows.map(row => {
        const concepts = action.payload.rows.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.conceptCode);
        const types = action.payload.rows.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.typeValue);

        return { ...row, concepts, types }
      });


      return {
        ...state,
        isLoading: false,
        rows: rowsWithConcepts,
        types: action.payload.types,
      };
    }
    case actionTypes.GET_DETAILS_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.GET_CONCEPTS:
      return { ...state, isLoadingConcepts: true };
    case actionTypes.GET_CONCEPTS_FULFILLED:
      return {
        ...state,
        isLoadingConcepts: false,
        concepts: action.payload.rows,
      };
    case actionTypes.GET_CONCEPTS_REJECTED:
      return { ...state, isLoadingConcepts: false };

    case actionTypes.CREATE:
      return { ...state, isLoading: true };
    case actionTypes.CREATE_FULFILLED: {
      const { rows, detail } = action.payload;

      const rowsWithConcepts = rows.map(row => {
        const concepts = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.conceptCode);
        const types = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.typeValue);
        return { ...row, concepts, types }
      });

      return {
        ...state,
        isLoading: false,
        rows: rowsWithConcepts,
      };
    }
    case actionTypes.CREATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.UPDATE:
      return { ...state, isLoading: true };
    case actionTypes.UPDATE_FULFILLED: {
      const { rows, detail } = action.payload;

      const rowsWithConcepts = rows.map(row => {
        const concepts = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.conceptCode);
        const types = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.typeValue);

        return { ...row, concepts, types }
      });

      return {
        ...state,
        isLoading: false,
        rows: rowsWithConcepts,
      };
    }
    case actionTypes.UPDATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DELETE:
      return { ...state, isLoading: true };
    case actionTypes.DELETE_FULFILLED: {
      const { rows, detail } = action.payload;

      const rowsWithConcepts = rows.map(row => {
        const concepts = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.conceptCode);
        const types = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.typeValue);

        return { ...row, concepts, types }
      });

      return {
        ...state,
        isLoading: false,
        rows: rowsWithConcepts,
      };
    }
    case actionTypes.DELETE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.DUPLICATE:
      return { ...state, isLoading: true };
    case actionTypes.DUPLICATE_FULFILLED: {
      const { rows, detail } = action.payload;

      const rowsWithConcepts = rows.map(row => {
        const concepts = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.conceptCode);
        const types = detail.filter(detail => detail.code === row.code).sort((a, b) => (a.order > b.order) ? 1 : -1).map(item => item.typeValue);
        return { ...row, concepts, types }
      });

      return {
        ...state,
        isLoading: false,
        rows: rowsWithConcepts,
      };
    }
    case actionTypes.DUPLICATE_REJECTED:
      return { ...state, isLoading: false };

    case actionTypes.RESET_STATE:
      return initialState;

    default:
      return state;
  }
}
