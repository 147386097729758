/* eslint-disable array-callback-return */
import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getConceptAndFormulasAPI,
  searchConceptsAPI,
  getConceptDetailsAPI,
  createConceptAndFormulasAPI,
  modifyConceptAndFormulasAPI,
  deleteConceptAndFormulasAPI,
  duplicateConceptAndFormulasAPI,
  getFunctionsAPI,
  searchFunctionsAPI,
  createFunctionAPI,
  modifyFunctionAPI,
  deleteFunctionAPI,
  uploadConceptsFormulasFormFileAPI,
  duplicateFunctionAPI,
} from "@icarius-connection/api";

//Concept and formulas
export const getConceptsAndFormulasRows = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CONCEPTS_AND_FORMULAS });
  try {
    let response = await getConceptAndFormulasAPI();
    let gridData = response.data && response.data.data;
    let grupos = response.data && response.data.grupos;
    let nominas = response.data && response.data.nominas;
    let acumuladores = response.data && response.data.acumuladores;
    let disponibilidad = response.data && response.data.disponibilidad;
    let familia = response.data && response.data.familia;
    let clasificacion = response.data && response.data.clasificacion;
    let discountArray = response.data && response.data.discountArray;
    let isAdmin = response.data && response.data.isAdmin;

    dispatch({
      type: actionTypes.GET_CONCEPTS_AND_FORMULAS_FULFILLED,
      payload: { gridData, grupos, nominas, acumuladores, disponibilidad, familia, clasificacion, discountArray, isAdmin, },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CONCEPTS_AND_FORMULAS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const searchConceptsAction = (query) => async (dispatch) => {
  dispatch({ type: actionTypes.SEARCH_CONCEPTS });
  try {
    let response = await searchConceptsAPI(`?token=${query}`);

    dispatch({
      type: actionTypes.SEARCH_CONCEPTS_FULFILLED
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEARCH_CONCEPTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const getConceptDetailsAction = (code) => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CONCEPT_DETAILS });
  try {
    let response = await getConceptDetailsAPI(`?code=${code}`);
    let maxSeq = response.data && response.data.maxSeq;
    let checkboxes = response.data && response.data.checkboxes;
    let formula = response.data && response.data.formula;
    let formulaPersonalizada = response.data && response.data.formulaPersonalizada;
    let esFormulaPersonalizada = response.data && response.data.esFormPersonalizada;

    dispatch({
      type: actionTypes.GET_CONCEPT_DETAILS_FULFILLED,
      payload: { maxSeq, checkboxes, formula, formulaPersonalizada, esFormulaPersonalizada },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CONCEPT_DETAILS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e;
  }
};

export const createConceptAndFormulasAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_CONCEPTS_AND_FORMULAS });
  try {
    let response = await createConceptAndFormulasAPI(data);
    let gridData = response.data && response.data.data;
    let status = response.data && response.data.status;

    dispatch({ type: actionTypes.CREATE_CONCEPTS_AND_FORMULAS_FULFILLED, payload: { gridData } });

    if (status === "OK") {
      dispatch(openSnackbarAction({ msg: "Concepto creado con éxito", severity: "success" }));
    }

    if (status === "OK_WARNING") {
      dispatch(openSnackbarAction({
        msg: "Concepto creado con éxito. Recuerde asignar el concepto a una estructura de ingreso o conjunto de conceptos",
        severity: "info",
        duration: null
      }));
    }

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir conceptos con el mismo código", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_CONCEPTS_AND_FORMULAS_REJECTED, payload: e });
    return e.response;
  }
};

export const modifyConceptAndFormulasAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_CONCEPTS_AND_FORMULAS });
  try {
    let response = await modifyConceptAndFormulasAPI(data);
    let gridData = response.data && response.data.data;
    let status = response.data && response.data.status;

    dispatch({ type: actionTypes.MODIFY_CONCEPTS_AND_FORMULAS_FULFILLED, payload: { gridData } });

    if (status === "OK") {
      dispatch(openSnackbarAction({ msg: "Concepto modificado con éxito", severity: "success" }));
    }

    if (status === "OK_WARNING") {
      dispatch(openSnackbarAction({
        msg: "Concepto modificado con éxito. Recuerde asignar el concepto a una estructura de ingreso o conjunto de conceptos",
        severity: "info",
        duration: null
      }));
    }

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir conceptos con el mismo código", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.MODIFY_CONCEPTS_AND_FORMULAS_REJECTED, payload: e });
    return e.response;
  }
};

export const deleteCentersSettingsAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_CONCEPTS_AND_FORMULAS });
  try {
    let response = await deleteConceptAndFormulasAPI(data);
    let gridData = response.data && response.data.data;

    dispatch({ type: actionTypes.DELETE_CONCEPTS_AND_FORMULAS_FULFILLED, payload: { gridData } });
    dispatch(openSnackbarAction({ msg: "Concepto eliminado con éxito", severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "CONCEPT_IN_USE") {
      dispatch(openSnackbarAction({ msg: "No es posible eliminar el concepto porque está en uso", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DELETE_CONCEPTS_AND_FORMULAS_REJECTED, payload: e });
    return e.response;
  }
};

export const duplicateConceptAndFormulasAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE_CONCEPTS_AND_FORMULAS });
  try {
    let response = await duplicateConceptAndFormulasAPI(data);
    let gridData = response.data && response.data.data;

    dispatch({ type: actionTypes.DUPLICATE_CONCEPTS_AND_FORMULAS_FULFILLED, payload: { gridData } });
    dispatch(openSnackbarAction({ msg: "Concepto duplicado con éxito", severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir conceptos con el mismo código", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DUPLICATE_CONCEPTS_AND_FORMULAS_REJECTED, payload: e });
    return e.response;
  }
};

//Functions
export const getFunctionRowsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_FUNCTIONS });
  try {
    let response = await getFunctionsAPI();
    let gridData = response.data && response.data.data;
    let applications = response.data && response.data.types;
    let maxSequences = response.data && response.data.maxSequences;
    let isAdmin = response.data && response.data.isAdmin;

    dispatch({
      type: actionTypes.GET_FUNCTIONS_FULFILLED,
      payload: { gridData, applications, maxSequences, isAdmin },
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_FUNCTIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const searchFunctionsAction = (query) => async (dispatch) => {
  dispatch({ type: actionTypes.SEARCH_FUNCTIONS });
  try {
    let response = await searchFunctionsAPI(`?token=${query}`);

    dispatch({
      type: actionTypes.SEARCH_FUNCTIONS_FULFILLED
    });
    return response;
  } catch (e) {
    dispatch({ type: actionTypes.SEARCH_FUNCTIONS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
  }
};

export const createFunctionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE_FUNCTION });
  try {
    let response = await createFunctionAPI(data);
    let gridData = response.data && response.data.data;
    let maxSequences = response.data && response.data.maxSequences;

    dispatch({ type: actionTypes.CREATE_FUNCTION_FULFILLED, payload: { gridData, maxSequences } });
    dispatch(openSnackbarAction({ msg: "Función creada con éxito", severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir funciones con el mismo código", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_FUNCTION_REJECTED, payload: e });
    return e.response;
  }
};

export const modifyFunctionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.MODIFY_FUNCTION });
  try {
    let response = await modifyFunctionAPI(data);
    let gridData = response.data && response.data.data;
    let maxSequences = response.data && response.data.maxSequences;

    dispatch({ type: actionTypes.MODIFY_FUNCTION_FULFILLED, payload: { gridData, maxSequences } });
    dispatch(openSnackbarAction({ msg: "Función modificada con éxito", severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir funciones con el mismo código", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.MODIFY_FUNCTION_REJECTED, payload: e });
    return e.response;
  }
};

export const deleteFunctionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE_FUNCTION });
  try {
    let response = await deleteFunctionAPI(data);
    let gridData = response.data && response.data.data;
    let maxSequences = response.data && response.data.maxSequences;

    dispatch({ type: actionTypes.DELETE_FUNCTION_FULFILLED, payload: { gridData, maxSequences } });
    dispatch(openSnackbarAction({ msg: "Función eliminada con éxito", severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "FUNCTION_IN_USE") {
      dispatch(openSnackbarAction({ msg: "No es posible eliminar la función porque está en uso", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DELETE_FUNCTION_REJECTED, payload: e });
    return e.response;
  }
};

export const duplicateFunctionAction = (data) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE_FUNCTION });
  try {
    let response = await duplicateFunctionAPI(data);
    let gridData = response.data && response.data.data;

    dispatch({ type: actionTypes.DUPLICATE_FUNCTION_FULFILLED, payload: { gridData } });
    dispatch(openSnackbarAction({ msg: "Función duplicada con éxito", severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir funciones con el mismo nombre", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DUPLICATE_FUNCTION_REJECTED, payload: e });
    return e.response;
  }
};

export const uploadConceptsFormulasFormFileAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.FORM_FILE_UPLOAD });
  try {
    let formData = new FormData();
    Object.keys(dataToSend).forEach((key) => formData.append(key, dataToSend[key]));
    let response = await uploadConceptsFormulasFormFileAPI(formData);
    let status = response.data && response.data.status;

    if (status !== "OK") {
      dispatch({ type: actionTypes.FORM_FILE_UPLOAD_REJECTED });
      dispatch(openSnackbarAction({ msg: getErrorStringFromError("defaultException"), severity: "error" }));
      return;
    }

    dispatch({
      type: actionTypes.FORM_FILE_UPLOAD_FULFILLED,
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.FORM_FILE_UPLOAD_REJECTED, payload: e });
    return e.response;
  }
};