import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchConceptsAction } from "../../actions";
import { getAppColor } from "src/app/selectors";
import { Tooltip, Grid, TextField, InputAdornment } from "@material-ui/core";
import { SearchIcon } from "@icarius-icons/";

const SearchConcept = ({ gridRef }) => {

  const [searchInput, setSearchInput] = useState("");

  const dispatch = useDispatch();
  const color = useSelector(getAppColor);

  const handleSearch = () => {
    let filterComponent;
    if (!Boolean(searchInput)) {
      filterComponent = gridRef.api.getFilterInstance("Código de concepto");
      filterComponent.resetFilterValues();
      gridRef.api.onFilterChanged();
      return;
    }

    dispatch(searchConceptsAction(searchInput))
      .then((resp) => {
        if (resp?.status === 200) {
          const keysToSelect = resp.data.result;

          filterComponent = gridRef.api.getFilterInstance("Código de concepto");
          filterComponent.setModel({ values: keysToSelect });
          gridRef.api.onFilterChanged();
        }
      })
  }

  return (
    <Tooltip title={"Buscar en fórmulas"}>
      <Grid container alignItems="center" style={{ flexWrap: "nowrap", marginLeft: 25, marginRight: 15 }}>
        <TextField
          style={{ width: 200, marginRight: 5 }}
          margin={"none"}
          label={"Buscar en fórmulas"}
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
              e.preventDefault();
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{ fill: color, cursor: "pointer" }} onClick={handleSearch} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Tooltip>
  );
}

export default SearchConcept;
