import * as actionTypes from "./actionTypes";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import { getErrorStringFromError } from "@icarius-localization/errors";
import {
  getConceptsSetAPI,
  createConceptsSetAPI,
  updateConceptsSetAPI,
  deleteConceptsSetAPI,
  getConceptsSetDetailsAPI,
  getConceptsSetConceptsAPI,
  createConceptsSetDetailAPI,
  deleteConceptsSetDetailAPI,
  duplicateConceptsSetAPI
} from "@icarius-connection/api";

export const getConceptGroupsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET });
  try {
    let response = await getConceptsSetAPI();
    dispatch({
      type: actionTypes.GET_FULFILLED,
      payload: {
        rows: response.data.data,
        setTypes: response.data.setTypes,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const getConceptGroupsDetailsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_DETAILS });
  try {
    let response = await getConceptsSetDetailsAPI();

    dispatch({
      type: actionTypes.GET_DETAILS_FULFILLED,
      payload: {
        rows: response.data.data,
        types: response.data.types,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_DETAILS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const getConceptsSetConceptsAction = () => async (dispatch) => {
  dispatch({ type: actionTypes.GET_CONCEPTS });
  try {
    let response = await getConceptsSetConceptsAPI();

    dispatch({
      type: actionTypes.GET_CONCEPTS_FULFILLED,
      payload: {
        rows: response.data.data,
      }
    });

    return response;
  } catch (e) {
    dispatch({ type: actionTypes.GET_CONCEPTS_REJECTED, payload: e });
    dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    return e.response;
  }
};

export const createConceptsSetAction = (key, concepts) => async (dispatch) => {
  dispatch({ type: actionTypes.CREATE });
  try {
    let response = await createConceptsSetAPI(key);
    let responseDetail = await createConceptsSetDetailAPI({ data: concepts });

    dispatch({
      type: actionTypes.CREATE_FULFILLED,
      payload: {
        rows: response.data.data,
        detail: responseDetail.data.data,
      }
    });
    dispatch(openSnackbarAction({ msg: 'Conjunto de conceptos creado con éxito', severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir conjuntos de conceptos con el mismo nombre", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.CREATE_REJECTED, payload: e });
    return e.response;
  }
};


export const updateConceptsSetAction = (key, oldConcepts, concepts) => async (dispatch) => {
  dispatch({ type: actionTypes.UPDATE });
  try {
    let response = await updateConceptsSetAPI(key);
    // Primero tengo que borrar los conceptos viejos
    if (oldConcepts.length > 0) {
      await deleteConceptsSetDetailAPI({ data: oldConcepts });
    }
    // Creo los nuevos
    let responseDetail = await createConceptsSetDetailAPI({ data: concepts });

    dispatch({
      type: actionTypes.UPDATE_FULFILLED,
      payload: {
        rows: response.data.data,
        detail: responseDetail.data.data,
      }
    });
    dispatch(openSnackbarAction({ msg: 'Conjunto de conceptos actualizado con éxito', severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir conjuntos de conceptos con el mismo nombre", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.UPDATE_REJECTED, payload: e });
    return e.response;
  }
};

export const deleteConceptsSetAction = (key, concepts) => async (dispatch) => {
  dispatch({ type: actionTypes.DELETE });
  try {
    let responseTest = await deleteConceptsSetAPI(key);
    return responseTest;
  } catch (e) {
    if (e.response.data.status === "DEPENDS_ON") {
      try {
        let responseDetail = await deleteConceptsSetDetailAPI({ data: concepts });

        if (responseDetail.status === 200) {
          let response = await deleteConceptsSetAPI(key);

          dispatch({
            type: actionTypes.DELETE_FULFILLED,
            payload: {
              rows: response.data.data,
              detail: responseDetail.data.data,
            }
          });
          dispatch(openSnackbarAction({ msg: 'Conjunto de conceptos eliminado con éxito', severity: "success" }));

          return response;
        }

        return responseDetail;
      } catch (e) {
        dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
      }

      dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
      return e.response;
    } else if (e.response.data.status === "IN_USE") {
      dispatch(openSnackbarAction({ msg: 'No se puede eliminar el conjunto de conceptos por estar en uso', severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DELETE_REJECTED, payload: e });
    return e.response;
  }
};

export const duplicateConceptsSetAction = (dataToSend) => async (dispatch) => {
  dispatch({ type: actionTypes.DUPLICATE });
  try {
    let response = await duplicateConceptsSetAPI(dataToSend);

    dispatch({
      type: actionTypes.DUPLICATE_FULFILLED,
      payload: {
        rows: response.data.data,
        detail: response.data?.detail,
      }
    });
    dispatch(openSnackbarAction({ msg: 'Conjunto de conceptos duplicado con éxito', severity: "success" }));

    return response;
  } catch (e) {
    if (e.response.data.status === "DUPLICATED_DATA") {
      dispatch(openSnackbarAction({ msg: "No pueden existir conjuntos de conceptos con el mismo nombre o codigo", severity: "error" }));
    } else {
      dispatch(openSnackbarAction({ msg: getErrorStringFromError(e), severity: "error" }));
    }
    dispatch({ type: actionTypes.DUPLICATE_REJECTED, payload: e });
    return e.response;
  }
};