import { useState, useEffect } from "react";

const useHandleKeys = (initialNames, initialValues) => {

    const [ keys, setKeys ] = useState();

    useEffect(() => {
        const auxKeys = {};
        initialNames.forEach(arr => {
            arr.forEach(item => {
                const newValueObj = initialValues.find(value => value.key === item.key);
                auxKeys[item.key] = newValueObj ? newValueObj.value : false;
            })
        })
        setKeys(auxKeys);
    }, [initialNames, initialValues])

    const changeKey = (key, value) => {
        const newKeys = {...keys};
        newKeys[key] = value;
        setKeys(newKeys);
    }

    return { keys, changeKey };
}

export default useHandleKeys;
