import React from "react";
import ScreenSelector from "@icarius-common/screenSelector";
import paths from "@icarius-localization/paths";
import {
  ConceptsIcon,
  CalculationFunctionIcon,
  ConceptGroupsIcon,
  AccumIcon,
  PayrollTypeIcon,
  CalculationReferenceIcon,
  ConceptStructureIcon,
  ConceptsSetIcon,
  SpecialProcessDefinitionIcon,
} from "@icarius-icons/";
import { getLocalizedString } from "@icarius-localization/strings";

const ConceptsFormulasFunctions = ({ history }) => {

  const data = [
    {
      name: "Conceptos y fórmulas",
      icon: <ConceptsIcon />,
      path: paths.conceptsAndFormulas,
    },
    {
      name: "Funciones de cálculo",
      icon: <CalculationFunctionIcon />,
      path: paths.calculationsFunctions,
    },
    {
      name: "Grupos para conceptos",
      icon: <ConceptGroupsIcon />,
      path: paths.conceptGroups,
    },
    {
      name: "Acumuladores",
      icon: <AccumIcon />,
      path: paths.accumulatorSettings,
    },
    {
      name: "Tipos de nómina",
      icon: <PayrollTypeIcon />,
      path: paths.payrollTypesSettings,
    },
    {
      name: "Referencias de cálculo",
      icon: <CalculationReferenceIcon />,
      path: paths.calculationReferences,
    },
    {
      name: "Estructuras de ingreso de conceptos",
      icon: <ConceptStructureIcon />,
      path: paths.conceptStructure,
    },
    {
      name: "Conjunto de conceptos",
      icon: <ConceptsSetIcon />,
      path: paths.conceptsSet
    },
    {
      name: "Definición de procesos especiales",
      icon: <SpecialProcessDefinitionIcon />,
      path: paths.specialProcessDefinition,
      onClick: () => {
        history.push({
          pathname: paths.specialProcessDefinition,
          state: {
            from: paths.conceptsAndFormulasScreenSelector,
          },
        });
      }
    },
  ];

  return (
    <ScreenSelector
      title={getLocalizedString("conceptsFormulasFunctions")}
      data={data}
    />
  );
}

export default ConceptsFormulasFunctions;
