import * as actionTypes from "./actionTypes";

const initialState = {
  loading: false,
  isAdmin: false,
  isLoadingFileUpload: false,

  //Concepts  
  gridData: null,
  grupos: null,
  nominas: null,
  acumuladores: null,
  disponibilidad: null,
  familia: null,
  clasificacion: null,
  maxSeq: null,
  checkboxes: null,
  formula: null,
  formulaPersonalizada: null,
  esFormulaPersonalizada: null,
  discountArray: null,

  //Functions
  functionsGridData: null,
  applications: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    //Conceptos
    case actionTypes.GET_CONCEPTS_AND_FORMULAS_FULFILLED:
      return {
        ...state,
        loading: false,
        gridData: action.payload.gridData,
        grupos: action.payload.grupos,
        nominas: action.payload.nominas,
        acumuladores: action.payload.acumuladores,
        disponibilidad: action.payload.disponibilidad,
        familia: action.payload.familia,
        clasificacion: action.payload.clasificacion,
        discountArray: action.payload.discountArray,
        isAdmin: action.payload.isAdmin,
      };

    case actionTypes.GET_CONCEPT_DETAILS_FULFILLED:
      return {
        ...state,
        maxSeq: action.payload.maxSeq,
        checkboxes: action.payload.checkboxes,
        formula: action.payload.formula,
        formulaPersonalizada: action.payload.formulaPersonalizada,
        esFormulaPersonalizada: action.payload.esFormulaPersonalizada,
        loading: false
      };

    case actionTypes.CLEAN_CONCEPT_DETAILS:
      return { ...state, maxSeq: null, checkboxes: null, formula: null };

    case actionTypes.MODIFY_CONCEPTS_AND_FORMULAS_FULFILLED:
    case actionTypes.CREATE_CONCEPTS_AND_FORMULAS_FULFILLED:
    case actionTypes.DELETE_CONCEPTS_AND_FORMULAS_FULFILLED:
    case actionTypes.DUPLICATE_CONCEPTS_AND_FORMULAS_FULFILLED:
      return { ...state, gridData: action.payload.gridData, loading: false };

    case actionTypes.DUPLICATE_CONCEPTS_AND_FORMULAS:
    case actionTypes.DELETE_CONCEPTS_AND_FORMULAS:
    case actionTypes.MODIFY_CONCEPTS_AND_FORMULAS:
    case actionTypes.GET_FUNCTIONS:
    case actionTypes.CREATE_CONCEPTS_AND_FORMULAS:
    case actionTypes.SEARCH_FUNCTIONS:
    case actionTypes.CREATE_FUNCTION:
    case actionTypes.MODIFY_FUNCTION:
    case actionTypes.DELETE_FUNCTION:
    case actionTypes.DUPLICATE_FUNCTION:
    case actionTypes.SEARCH_CONCEPTS:
    case actionTypes.GET_CONCEPT_DETAILS:
    case actionTypes.GET_CONCEPTS_AND_FORMULAS:
      return { ...state, loading: true };

    case actionTypes.DUPLICATE_CONCEPTS_AND_FORMULAS_REJECTED:
    case actionTypes.MODIFY_CONCEPTS_AND_FORMULAS_REJECTED:
    case actionTypes.DELETE_CONCEPTS_AND_FORMULAS_REJECTED:
    case actionTypes.GET_FUNCTIONS_REJECTED:
    case actionTypes.SEARCH_FUNCTIONS_FULFILLED:
    case actionTypes.SEARCH_FUNCTIONS_REJECTED:
    case actionTypes.CREATE_FUNCTION_REJECTED:
    case actionTypes.MODIFY_FUNCTION_REJECTED:
    case actionTypes.DELETE_FUNCTION_REJECTED:
    case actionTypes.DUPLICATE_FUNCTION_REJECTED:
    case actionTypes.SEARCH_CONCEPTS_FULFILLED:
    case actionTypes.SEARCH_CONCEPTS_REJECTED:
    case actionTypes.GET_CONCEPTS_AND_FORMULAS_REJECTED:
    case actionTypes.GET_CONCEPT_DETAILS_REJECTED:
    case actionTypes.CREATE_CONCEPTS_AND_FORMULAS_REJECTED:
      return { ...state, loading: false };

    case actionTypes.GET_FUNCTIONS_FULFILLED:
      return {
        ...state,
        loading: false,
        functionsGridData: action.payload.gridData,
        applications: action.payload.applications,
        maxSequences: action.payload.maxSequences,
        isAdmin: action.payload.isAdmin,
      };

    case actionTypes.CREATE_FUNCTION_FULFILLED:
    case actionTypes.MODIFY_FUNCTION_FULFILLED:
    case actionTypes.DELETE_FUNCTION_FULFILLED:
      return {
        ...state,
        functionsGridData: action.payload.gridData,
        loading: false,
        maxSequences: action.payload.maxSequences,
      };

    case actionTypes.DUPLICATE_FUNCTION_FULFILLED:
      return { ...state, functionsGridData: action.payload.gridData, loading: false };

    case actionTypes.FORM_FILE_UPLOAD:
      return { ...state, isLoadingFileUpload: true };
    case actionTypes.FORM_FILE_UPLOAD_FULFILLED:
      return { ...state, isLoadingFileUpload: false };
    case actionTypes.FORM_FILE_UPLOAD_REJECTED:
      return { ...state, isLoadingFileUpload: false };

    default:
      return state;
  }
}
