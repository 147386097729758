import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConceptsAndFormulasRows, getConceptDetailsAction, deleteCentersSettingsAction } from "../../actions";
import { getGridData, getLoading } from "../../selectors";
import { getLocalizedString } from "@icarius-localization/strings";
import { getIsExportingGrid } from "src/app/selectors";
import paths from "@icarius-localization/paths";
import CommonPage from "@icarius-common/commonPage";
import Form from "./form/form";
import SearchConcept from "./searchConcept";
import DuplicateDialog from "./duplicateDialog";
import { openSnackbarAction } from "@icarius-common/snackbar/actions";
import CustomIconButton from "@icarius-common/abmComponents/customIconButton";
import MenuItemWithIcon from "@icarius-common/MenuItemWithIcon";
import ListIcon from '@material-ui/icons/List';
import StructuresDialog from "./structuresDialog";
import { openDialogAction } from "@icarius-common/dialog/actions";

const ConceptsFormulas = ({ handleGoBack }) => {

  const [selectedConcept, setSelectedConcept] = useState(null);
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [duplicateIsOpen, setDuplicateIsOpen] = useState(false);
  const [structuresIsOpen, setStructuresIsOpen] = useState(false);

  const dispatch = useDispatch();
  const exportingGridStatus = useSelector(getIsExportingGrid);

  const gridData = useSelector(getGridData);
  const loading = useSelector(getLoading);

  useEffect(() => {
    dispatch(getConceptsAndFormulasRows());
  }, [dispatch])

  const handleOpenStructures = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un único concepto", severity: "warning" }));
      return;
    }

    setSelectedConcept(selectedRows[0]);
    setStructuresIsOpen(true);
  };

  const handleCloseStructures = () => {
    setSelectedConcept(null);
    setStructuresIsOpen(false);
  };

  const handleDelete = (concept) => {
    const dataToSend = {
      "Código de concepto": concept["Código de concepto"],
      "Nombre del concepto": concept["Nombre del concepto"],
      "Secuencia de cálculo": concept["Secuencia de cálculo"]
    }
    dispatch(deleteCentersSettingsAction(dataToSend))
  }

  const handleOpenDelete = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un único concepto", severity: "warning" }));
      return;
    }

    dispatch(openDialogAction({
      title: "Eliminar concepto",
      msg: "¿Desea eliminar el concepto?",
      onConfirm: () => handleDelete(selectedRows[0]),
    }));
  };

  const handleOpenDuplicate = (gridRef) => {
    let selectedRows = gridRef.api.getSelectedRows();

    // Si no hay seleccionado, pido seleccionar
    if (selectedRows.length !== 1) {
      dispatch(openSnackbarAction({ msg: "Debe seleccionar un único concepto", severity: "warning" }));
      return;
    }

    setSelectedConcept(selectedRows[0]);
    setDuplicateIsOpen(true);
  };

  const handleCloseDuplicate = () => {
    setSelectedConcept(null);
    setDuplicateIsOpen(false);
  }

  const handleOpenForm = (row) => {
    const data = row?.data || null;

    dispatch(getConceptDetailsAction(data ? data["Código de concepto"] : ""))
      .then((resp) => {
        if (resp.status === 200) {
          setSelectedConcept(data);
          setFormIsOpen(true);
        }
      })
  };

  const handleCloseForm = () => {
    setFormIsOpen(false);
    setSelectedConcept(null);
  }

  const goBackItem = () =>
    <CustomIconButton
      title={getLocalizedString("goBack")}
      onClick={handleGoBack}
      type={"goBack"}
    />

  const addItem = () =>
    <CustomIconButton
      title={"Nuevo concepto"}
      onClick={() => handleOpenForm()}
      type={"add"}
    />

  const deleteItem = (gridRef) =>
    <CustomIconButton
      title={getLocalizedString("delete")}
      onClick={() => handleOpenDelete(gridRef)}
      type={"delete"}
    />

  const structuresItem = (gridRef) =>
    <CustomIconButton
      title={'Estructuras de ingreso'}
      onClick={() => handleOpenStructures(gridRef)}
    >
      <ListIcon />
    </CustomIconButton>

  const duplicateItem = (gridRef) =>
    <CustomIconButton
      title={"Duplicar concepto"}
      onClick={() => handleOpenDuplicate(gridRef)}
      type={"duplicate"}
    />

  const searchItem = (ref) => <SearchConcept gridRef={ref} />

  const helpItems = [
    <MenuItemWithIcon
      key={"b"}
      value={"/resources/ICARIUS - Dashboard de Nómina - KPI y Gráficos.pdf"}
      text={'Creación de KPI y Gráficos para el Dashboard de Nómina'}
      type={"file"}
    />,
  ]

  return (
    <>
      {
        Boolean(formIsOpen) ?
          <Form data={selectedConcept} handleClose={handleCloseForm} />
          :
          <CommonPage
            columnDefPage={paths.conceptsAndFormulas}
            title={getLocalizedString("conceptsAndFormulas")}
            gridTitle={getLocalizedString("conceptsAndFormulas")}
            menuItems={[goBackItem, searchItem, structuresItem, addItem, duplicateItem, deleteItem]}
            rowData={gridData}
            handleRowClick={handleOpenForm}
            isLoading={loading || exportingGridStatus}
            hasHelp
            hasExpand
            hasSelectAll
            helpItems={helpItems}
          >
            {
              duplicateIsOpen && !loading &&
              <DuplicateDialog
                open={duplicateIsOpen}
                data={selectedConcept}
                handleClose={handleCloseDuplicate}
              />
            }
            {
              structuresIsOpen && !loading &&
              <StructuresDialog
                open={structuresIsOpen}
                data={selectedConcept}
                handleClose={handleCloseStructures}
              />
            }
          </CommonPage>
      }
    </>
  );
}

export default ConceptsFormulas;
